import { Grid, Typography } from "@mui/material";
import styled from "styled-components";

/// PAYMENTMETHODS
export const Container = styled(Grid)`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 960px) {
    display: none;
  }
`;
export const Image = styled.img`
  width: 100%;
`;

export const Title = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;

    color: #ffffff;

    @media (max-width: 1280px) {
      font-size: 15px;
    }

    @media (max-width: 1024px) {
      font-size: 13px;
    }
  }
`;
/// PAYMENTMETHODS
