import { makeAutoObservable, toJS } from "mobx";
import { makePersistable } from "mobx-persist-store";

interface IDataCorrector {
  ea: string | null;
  ev: string | null;
  aa: string | null;
  np: string | null;
  ep: string | null;
}
interface IData {
  corrector: IDataCorrector;
}

class CorrectorAndProducerStore {
  data: IData = {} as IData;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "correctorAndProducer",
      properties: ["data"],
      storage: window.sessionStorage,
    });
  }

  get correctorData() {
    return toJS(this.data.corrector);
  }

  setCorrector = (corrector: IDataCorrector) => {
    this.data.corrector = corrector;
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CorrectorAndProducerStore();
