import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

interface IProduct {
  p_id: number;
  p_plan: string;
  p_counter: number;
  p_price: number;
  p_name: string;
}

class ProductStore {
  product: IProduct = {} as IProduct;
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "product",
      properties: ["product"],
      storage: window.sessionStorage,
    });
    this.product.p_counter = 1;
  }

  defaultValues = () => {
    this.product.p_counter = 1;
    this.product.p_id = 11;
    this.product.p_plan = "mensal";
    this.product.p_price = 0;
  };

  get productInStore() {
    return this.product.p_id;
  }

  get productId() {
    return this.product.p_id ?? 11;
  }

  get productPlan() {
    return this.product.p_plan ?? "mensal";
  }

  get productCounter() {
    return this.product.p_counter ?? 1;
  }

  get productPrice() {
    return this.product.p_price ?? 0;
  }

  get productName() {
    return this.product.p_name ?? null;
  }

  setId = (id: number) => {
    this.product.p_id = id;
  };

  setPlan = (plan: string) => {
    this.product.p_plan = plan;
  };

  setCounter = (counter: number) => {
    this.product.p_counter = counter;
  };

  setPrice = (price: number) => {
    this.product.p_price = price;
  };

  setProduct = (
    id: number,
    plan: string,
    counter: number,
    price: number,
    name: string
  ) => {
    this.product.p_id = id;
    this.product.p_plan = plan;
    this.product.p_counter = counter;
    this.product.p_price = price;
    this.product.p_name = name;
  };
}

export default new ProductStore();
