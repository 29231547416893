import { Grid, Typography } from "@mui/material";
import styled from "styled-components";

type ISocialMediaIconProps = {
  src: string;
};

export const SocialMediaContainer = styled(Grid)``;

export const SocialMediaItem = styled(Grid)`
  display: flex;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const SocialMediaText = styled(Typography)`
  && {
    color: #ffffff;
    font-size: 1.2rem;
  }
`;

export const SocialMediaLink = styled.a<ISocialMediaIconProps>`
  background: url(${(props) => props.src}) no-repeat;
  background-position: center;
  width: 100%;
  height: 36px;
  cursor: pointer;
  &:first-child {
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    background-size: 24px 24px;
  }
`;
