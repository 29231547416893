import { Box, Typography } from "@mui/material";
import styled from "styled-components";

import BannerImage from "../../../assets/images/banner-home.png";

export const StyledBox = styled(Box)`
  background-size: cover;
  background-position: center;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const ImgName = styled.img`
  width: 115px;
  height: 18px;
  margin-left: 45px;
  margin-bottom: 5px;
`;

export const DesktopVersion = styled.div`
  && {
    display: block;

    @media (max-width: 475px) {
      display: none;
    }
  }
`;

export const MobileVersion = styled.div`
  && {
    display: none;
    height: 565px;
    background-image: url(${BannerImage});
    background-size: cover;
    background-color: #ff5000;
    background-position: -950px 0px;
    padding: 36px 33px;
    @media (max-width: 475px) {
      display: block;
    }
  }
`;

export const Title = styled(Typography)`
  && {
    text-align: center;
    font-family: Aestetico;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 26.4px;
    color: #ffffff;
  }
`;

export const Subtitle = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 33px;

    color: #ffffff;
  }
`;
