import { AccordionSummary } from "@mui/material";
import { useState } from "react";
import {
  CustomAccordion,
  Icon,
  IconContainer,
  Title,
} from "../styles/MobileCardStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { loadLocalImage } from "../../../../../../../utils";
import { CardProps } from "../types";

export const AdvantageMobileCard = ({
  text,
  icon,
  index,
}: CardProps) => {
  const [expanded, setExpanded] = useState<string | false>("card0");

  const handleChange =
    (card: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? card : false);
    };

  return (
    <>
      <CustomAccordion
        expanded={expanded === `panel${index}`}
        onChange={handleChange(`panel${index}`)}
      >
        <AccordionSummary
          aria-controls="cardadv-content"
          id={`card${index}adv-header`}
          expandIcon={<ExpandMoreIcon htmlColor="#146cfa" />}
        >
          <IconContainer>
            <Icon src={loadLocalImage(icon, "advantages")} />
          </IconContainer>
          <Title variant="h6" $isexpanded={expanded === `panel${index}`}>
            {text}
          </Title>
        </AccordionSummary>
      </CustomAccordion>
    </>
  );
};
