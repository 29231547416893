import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import useWindowDimensions from "../../../../../../hooks/useWindowDimensions";
import dataCards from "../../data/CardsData.json";
import { AdvantageDesktopCard } from "./components/DesktopCard";
import { AdvantageMobileCard } from "./components/MobileCard";
import { CardProps } from "./types";

export const AdvantageCards = () => {
  const [data, setData] = useState<CardProps[]>([]);
  const { width } = useWindowDimensions();

  useEffect(() => {
    const { cardsData } = dataCards;
    setData(cardsData);
  }, []);

  return (
    <Grid container spacing={4}>
      {data.map(({ text, icon, hoverText }, index) => (
        <Grid
          key={index}
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
          alignItems={"center"}
        >
          {width <= 720 ? (
            <AdvantageMobileCard
              index={index}
              text={text}
              icon={icon}
              hoverText={hoverText}
            />
          ) : (
            <AdvantageDesktopCard
              text={text}
              icon={icon}
              hoverText={hoverText}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};
