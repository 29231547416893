/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Skeleton } from "@mui/material";
import { FlexGrid } from "../../common/grid/components/FlexGrid";
import {
  Title,
  Subtitle,
  Text,
  List,
  StyledBox,
  ImageText,
  ProductGrid,
  MenuButton,
  StyledMenu,
  StyledMenuItem,
  Item,
  IconsGrid,
  MenuDivider,
  IconsImage,
  PlanCard,
  CardHeader,
  BenefitImage,
  BenefitTitle,
  BenefitText,
  BenefitBox,
  BenefitDivider,
  RegistryBox,
  RegistryText,
  BenefitObs,
} from "../styles/ProductStyles";

import Tooth from "../../../assets/images/details/tooth.svg";
import ArrowUp from "../../../assets/images/arrow-up.svg";
import ArrowDown from "../../../assets/images/arrow-down.svg";

import { Fragment, useEffect, useState } from "react";
import productsData from "../../home/definitions/ProductsData";

import { useLocation } from "react-router";
import useStores from "../../../hooks/useStores";
import { observer } from "mobx-react-lite";
import useGoogleTagManager from "../../../hooks/useGTM";
import { getProducts } from "../../../gateway/requests/ProductsRequests";
import {
  BoxButton,
  MiniBoxPrice,
  Observation,
  PriceBoxText,
} from "../../home/components/Products/styles/ProductsStyles";
import Button from "../../common/button/components/Button";
import { setQueryParams } from "../../../utils";

const Product = observer(() => {
  const { pathname } = useLocation();
  const { productStore, correctorAndProducerStore } = useStores();
  const { pageInfoEvent, interactionEvent } = useGoogleTagManager();
  const [products, setProducts] = useState<any>(productsData);
  const [product, setProduct] = useState(
    productsData.find((data: any) => data.id === productStore.productId) ??
      products[0]
  );

  const page_location = `/produto/${product.title
    .replace(" ", "-")
    .toLowerCase()}`;

  useEffect(() => {
    pageInfoEvent({
      page_location,
      page_path: pathname,
    });
  }, []);

  const gtmHandleClick = () => {
    interactionEvent({
      event_action: "clicou",
      event_category: "pagina_plano",
      event_label: "editar_plano",
      event_name: `editar_plano`,
    });
  };

  const [productsMenu, setProductsMenu] = useState<any>(
    productsData
      .filter((data: any) => data.id !== productStore.productId)
      .map((data: any) => data.title)
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    gtmHandleClick();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateOut = () => {
    const { ev, ea, aa, np, ep } = correctorAndProducerStore.correctorData;
    if (ev || ea || aa || np || ep) {
      window.open(
        setQueryParams(`${process.env.REACT_APP_ODONTO_SITE}`, {
          ev,
          ea,
          aa,
          np,
          ep,
        })
      );
    } else {
      window.open(process.env.REACT_APP_ODONTO_SITE);
    }
  };

  const handleSelectedProduct = (prod: string) => {
    const selected = products.find((data: any) => data.title === prod);
    const unselected = products.filter(
      (data: any) => data.title !== selected?.title
    );

    setProduct(selected!);
    setProductsMenu(unselected.map((data: any) => data.title));

    productStore.setProduct(
      selected.id,
      productStore.productPlan,
      productStore.productCounter,
      selected.fullPrice,
      product.title
    );

    handleClose();
  };

  const defineProduct = async () => {
    let newProducts = null;
    const response = await getProducts("430");

    if (response.status === 200 || response.status === 201) {
      const planos = response.data.retorno;

      newProducts = productsData.map((data: any) => {
        const plano = planos.find(
          (planoData: any) => planoData.codigo_plano === data.id
        );
        const splitPriceMonth = plano.valor_preco_base.toFixed(2).split(".");

        return {
          ...data,
          fullPrice: plano.valor_preco_base,
          price: splitPriceMonth[0],
          decimalPrice: splitPriceMonth[1],
          iof: plano.aliquota_iof,
        };
      });

      const newProduct =
        newProducts.find((data: any) => data.id === productStore.productId) ??
        products[0];

      setProducts(newProducts);
      setProduct(newProduct);
      setProductsMenu(
        newProducts
          .filter((data: any) => data.id !== productStore.productId)
          .map((data: any) => data.title)
      );

      productStore.setProduct(
        newProduct.id,
        productStore.productPlan,
        productStore.productCounter,
        newProduct.fullPrice,
        product.title
      );
    }

    return null;
  };

  useEffect(() => {
    defineProduct();
  }, []);

  return (
    <StyledBox>
      <ProductGrid
        container
        sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={9.5}>
              <FlexGrid container justifyContent="left" alignItems="baseline">
                <Title>{product.title}</Title>
                <MenuButton
                  id="menu-button"
                  aria-controls={open ? "menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  {open ? (
                    <img src={ArrowUp} alt="arrow-up" />
                  ) : (
                    <img src={ArrowDown} alt="arrow-down" />
                  )}{" "}
                  <p style={{ marginLeft: "5px" }}>Editar Plano</p>
                </MenuButton>
                <StyledMenu
                  id="menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "menu-button",
                  }}
                >
                  {productsMenu.map((data: any, index: number) => (
                    <div key={data}>
                      <StyledMenuItem
                        onClick={() => handleSelectedProduct(data)}
                      >
                        <Item>{data}</Item>
                      </StyledMenuItem>
                      {index < productsMenu.length - 1 && (
                        <MenuDivider orientation="horizontal" />
                      )}
                    </div>
                  ))}
                </StyledMenu>
              </FlexGrid>
              <Subtitle>{product.subtitle}</Subtitle>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2.5}
              marginTop={{ xs: 2, sm: 2, md: 0 }}
            >
              <Grid container>
                {product.price && (
                  <FlexGrid
                    item
                    flexDirection={"column"}
                    alignItems={"center"}
                    xs={12}
                  >
                    <Grid item xs={12}>
                      <MiniBoxPrice>
                        R$ <span>{product.price}</span>,{product.decimalPrice}*
                      </MiniBoxPrice>
                    </Grid>
                    <Grid item xs={12}>
                      <PriceBoxText>mensais por pessoa</PriceBoxText>
                    </Grid>
                  </FlexGrid>
                )}
                {!product.price && <Skeleton width="100%" />}
                <Grid item xs={12} marginBottom={0.5} justifyContent={"center"}>
                  <BoxButton>
                    <Button
                      type="button"
                      variant="contained"
                      background="fill"
                      width="232px"
                      bgcolor="#FF5000"
                      onClick={navigateOut}
                    >
                      Contrate Já
                    </Button>
                  </BoxButton>
                </Grid>
                <Grid item xs={12}>
                  <Observation>
                    *Acréscimo de IOF (
                    {product.iof !== null ? (
                      product.iof.toFixed(2).replace(".", ",")
                    ) : (
                      <Skeleton
                        sx={{ display: "inline-flex", width: "10px" }}
                      />
                    )}
                    %)
                  </Observation>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ margin: "30px 0" }}>
            {product.icons.map((data: any, index: number) => (
              <IconsGrid item xs={12} sm={4} key={`icon_${index}`}>
                <IconsImage src={data.icon} alt={`odonto_${index}`} />
                <ImageText>{data.text}</ImageText>
              </IconsGrid>
            ))}
          </Grid>
          <Subtitle>
            Ampla cobertura com {product.procedures.count} procedimentos.
            Conheça os principais:
          </Subtitle>
          <Grid container marginTop={5} marginBottom={5}>
            {product.procedures.descriptions &&
              product.procedures.descriptions.map(
                (data: any, index: number) => (
                  <Grid item xs={12} md={4} key={`detalhes_${index}`}>
                    <List>
                      <img src={Tooth} alt="tooth-icon" />
                      <Text sx={{ marginLeft: "10px" }}>{data}</Text>
                    </List>
                  </Grid>
                )
              )}
          </Grid>
          <PlanCard>
            <CardHeader>Características deste plano</CardHeader>
            <BenefitBox>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <Grid container>
                    <FlexGrid item xs={2} sm={1}>
                      <BenefitImage src={product.moreBenefits[0][0].icon} />
                    </FlexGrid>
                    <Grid item xs={10} sm={11}>
                      <BenefitTitle>
                        {product.moreBenefits[0][0].title}
                      </BenefitTitle>
                      {product.moreBenefits[0][0].specialText?.map(
                        (text: any, index: number) => (
                          <Grid
                            container
                            sx={{ marginBottom: "10px" }}
                            key={`special_mbenefits_${index}`}
                          >
                            <Grid item xs={12}>
                              <BenefitText>{text[0]}</BenefitText>
                            </Grid>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container>
                    <FlexGrid item xs={2} sm={1}>
                      <BenefitImage src={product.moreBenefits[0][1].icon} />
                    </FlexGrid>
                    <Grid item xs={10} sm={11}>
                      <BenefitTitle>
                        {product.moreBenefits[0][1].title}
                      </BenefitTitle>
                      <Grid container sx={{ marginBottom: "10px" }}>
                        <Grid item xs={12}>
                          <BenefitText>
                            {product.moreBenefits[0][1].text}
                          </BenefitText>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Grid container>
                    <FlexGrid item xs={2} sm={1}>
                      <BenefitImage src={product.moreBenefits[0][2].icon} />
                    </FlexGrid>
                    <Grid item xs={10} sm={11}>
                      <BenefitTitle>
                        {product.moreBenefits[0][2].title}
                      </BenefitTitle>
                      <Grid container sx={{ marginBottom: "10px" }}>
                        <Grid item xs={12}>
                          <BenefitText>
                            {product.moreBenefits[0][2].text}
                          </BenefitText>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </BenefitBox>
            <BenefitDivider />
            {product.moreBenefits.slice(1).map((data: any, index: number) => (
              <Fragment key={`mbenefits_${index}`}>
                <BenefitBox>
                  <Grid container spacing={1}>
                    {data.map((benefit: any, bindex: number) => (
                      <Grid item xs={12} sm={2.3} key={`benefit_${bindex}`}>
                        <Grid container>
                          <FlexGrid item xs={2}>
                            <BenefitImage src={benefit.icon} />
                          </FlexGrid>
                          <Grid item xs={10}>
                            <BenefitTitle>{benefit.title}</BenefitTitle>
                            <BenefitText>{benefit.text}</BenefitText>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </BenefitBox>
                <BenefitDivider />
              </Fragment>
            ))}
          </PlanCard>
          <BenefitObs>
            **Isenção de carência para as adesões realizadas nos primeiros 30
            dias após a contratação do plano. Para adesões realizadas após esse
            período, serão aplicadas as carências contratuais.
          </BenefitObs>
          <PlanCard>
            <CardHeader color="#FF5000">Vantagens deste plano</CardHeader>
            {product.moreAdvantages.map((data: any, index: number) => (
              <Fragment key={`madvantages_${index}`}>
                <BenefitBox>
                  <Grid container spacing={2}>
                    {data.map((benefit: any, bindex: number) => (
                      <Grid item xs={12} sm={3} key={`advantage_${bindex}`}>
                        <Grid container>
                          <FlexGrid item xs={1.5}>
                            <BenefitImage src={benefit.icon} />
                          </FlexGrid>
                          <Grid item xs={10.5}>
                            <BenefitTitle>{benefit.title}</BenefitTitle>
                            <BenefitText>{benefit.text}</BenefitText>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </BenefitBox>
                <BenefitDivider />
              </Fragment>
            ))}
          </PlanCard>
        </Grid>
      </ProductGrid>
      <RegistryBox>
        <RegistryText>{product.registry}</RegistryText>
        <RegistryText>Nome Técnico ANS: {product.registryDesc}</RegistryText>
      </RegistryBox>
    </StyledBox>
  );
});

export default Product;
