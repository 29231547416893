import { getRequest } from "../HttpRequest";

export const getProducts = (cod: string) => {
  const url = `${process.env.REACT_APP_CALCULATION}`.replace(
    "{codigo-produto}",
    cod
  );

  return getRequest(url);
};
