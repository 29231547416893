import styled from "styled-components";
import { Accordion, Box, Typography } from "@mui/material";

const mainColor = "#146cfa";

interface TitleProps {
  $isexpanded: boolean;
}

export const CustomAccordion = styled(Accordion)`
  && {
    background: #ffffff;
    border: 0.5px solid rgba(20, 108, 250, 0.25);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    position: relative;
    padding: 18px;
  }
`;

export const Content = styled(Box)`
  display: flex;
  align-items: center;
  position: relative;
`;

export const IconContainer = styled(Box)`
  background-color: ${mainColor};
  position: absolute;
  left: -18px;
  top: -19px;
  width: 70px;
  height: 88px;
  display: flex;
  align-items: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  @media screen and (max-width: 375px) {
    height: 113px;
  }
`;

export const Icon = styled.img`
  width: 100%;
  padding: 20px;
`;

export const Title = styled(Typography)<TitleProps>`
  && {
    color: #146cfa;
    font-weight: 700;
    margin-left: 70px;
    font-size: 1rem;

    @media screen and (max-width: 375px) {
      width: 80px;
    }
  }
`;
export const SubTitle = styled(Typography)`
  && {
    color: rgba(60, 60, 67, 0.85);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
`;
