import BrazilIcon from "../../../assets/images/details/brazil-icon.svg";
import DiscountIcon from "../../../assets/images/details/discount-icon.svg";

import CalendarIcon from "../../../assets/images/details/calendar-icon.svg";
import CalendarIcon2 from "../../../assets/images/details/calendar-icon2.svg";
import MedalIcon from "../../../assets/images/details/medal-icon.svg";
import LocalIcon from "../../../assets/images/details/local-icon.svg";
import EligibilityIcon from "../../../assets/images/details/eligibility-icon.svg";
import NotIncludedIcon from "../../../assets/images/details/not-included-icon.svg";
import ToothIcon from "../../../assets/images/details/tooth.svg";
import DoctorIcon from "../../../assets/images/details/doctor-icon.svg";
import QuestionIcon from "../../../assets/images/details/question-icon.svg";
import IncludedIcon from "../../../assets/images/details/included-icon.svg";

const productsData = [
  {
    id: 25439,
    codMonth: process.env.REACT_APP_environment === "master" ? 25435 : 25401,
    codYear: process.env.REACT_APP_environment === "master" ? 25436 : 25402,
    title: "Mais",
    subtitle: "Rol Ampliado",
    text: "Saúde bucal para você e seus colaboradores com economia para sua empresa.",
    fullPrice: null,
    fullPriceYear: null,
    price: null,
    decimalPrice: null,
    priceYear: null,
    decimalPriceYear: null,
    iof: null,
    registry: "Registro: 494872236",
    registryDesc: "Mais Empresarial Rol Ampliado",
    bottomText:
      "**Isenção de carência para as adesões realizadas nos primeiros 30 dias após a contratação do plano. Para adesões realizadas após esse período, serão aplicadas as carências contratuais.",
    procedures: {
      count: 219,
      descriptions: [
        "Urgência e Emergências 24h",
        "Tratamento de canal dentário",
        "Prótese simples unitárias - (em resina e metal)",
        "Restaurações",
        "Tratamento gengival",
        "Radiografias",
        "Cirurgias e extrações",
        "Clareamento unitário de dente desvitalizado",
        "Limpezas, prevenção e aplicação de flúor",
        "Biópsias de boca",
      ],
    },
    benefits: [
      "Consultas - inicial e urgência",
      "Manutenção da saúde bucal",
      "+ de 200 coberturas com o melhor custo benefício",
    ],
    moreBenefits: [
      [
        {
          icon: CalendarIcon,
          title: "Carências**",
          specialText: [
            ["24hs ------ Urgência e Emergência"],
            [
              "30 dias --- Diagnóstico, Radiologia, Prevenção, Dentística, Periodontia, Odontopediatria e  DTM",
            ],
            ["180 dias -- Endodontia, Cirurgia e Prótese do Rol ANS"],
          ],
        },
        {
          icon: LocalIcon,
          title: "Ampla rede nacional",
          text: "Com + de 34 mil opções de atendimentos.",
        },
        {
          icon: EligibilityIcon,
          title: "Elegibilidade até 3º grau consanguíneo",
          text: "Cônjuge; companheiro(a); filhos(as); enteado(a) e tutelado(a); pai, mãe, irmãos, irmãs, avós, avôs, netos(as), tios(as), sobrinho(as), bisnetos(as), sogro(a), genro, nora, padrasto, madrasta e cunhado(a)",
        },
      ],
      [
        {
          icon: EligibilityIcon,
          title: "Para todas as idades",
          text: "",
        },
        {
          icon: NotIncludedIcon,
          title: "Não possui Coparticipação",
          text: "",
        },
        {
          icon: DiscountIcon,
          title: "Possui Reembolso",
          text: "",
        },
        {
          icon: DoctorIcon,
          title: "219 procedimentos, incluídos ROL ANS",
          text: "",
        },
        {
          icon: QuestionIcon,
          title: "Dúvidas? Consulte nossa FAQ",
          text: "",
        },
      ],
    ],
    moreAdvantages: [
      [
        {
          icon: DoctorIcon,
          title: "Descontos de até 70% em farmácias",
          text: "Medicamentos e itens de higiene bucal",
        },
        {
          icon: IncludedIcon,
          title: "SulAMais",
          text: "Clube de vantagens com descontos e benefícios exclusivos",
        },
        {
          icon: ToothIcon,
          title: "Aplicativo SulAmérica Odonto",
          text: "Carteirinha digital, rede credenciada, procedimentos cobertos e mais.",
        },
        {
          icon: QuestionIcon,
          title: "Central de Atendimento 24h",
          text: "",
        },
      ],
    ],
    checkItems: [
      {
        text: "219 procedimentos",
        icon: "white_teeth.svg",
      },
      {
        text: "Cirurgias",
        icon: "blue_teeth.svg",
      },
      {
        text: "Limpezas",
        icon: "blue_teeth.svg",
      },
      {
        text: "Restaurações e Próteses do Rol ANS",
        icon: "blue_teeth.svg",
      },
    ],
    icons: [
      {
        text: "3º benefício mais desejado entre colaboradores",
        icon: MedalIcon,
      },
      {
        text: "Rede especializada e nacional",
        icon: BrazilIcon,
      },
      {
        text: "Sem carência",
        icon: CalendarIcon2,
      },
    ],
  },
  {
    id: 25440,
    codMonth: process.env.REACT_APP_environment === "master" ? 25425 : 25391,
    codYear: process.env.REACT_APP_environment === "master" ? 25426 : 25394,
    title: "Mais Doc",
    subtitle: "Cobertura ampliada + documentação",
    text: "O plano ideal para oferecer saúde bucal com cobertura adicional de Documentação Ortodôntica",
    fullPrice: null,
    fullPriceYear: null,
    price: null,
    decimalPrice: null,
    priceYear: null,
    decimalPriceYear: null,
    iof: null,
    registry: "Registro ANS: 494857232",
    registryDesc: "Mais Doc Empresarial Rol Ampliado + Doc Ortodôntica",
    procedures: {
      count: 230,
      descriptions: [
        "Restaurações",
        "Radiografias",
        "Limpezas, prevenção e aplicação de flúor",
        "Tratamento de canal dentário",
        "Tratamento gengival (de todas as doenças associada a gengiva)",
        "Cirurgias e extrações (incluindo dente do Siso e inclusos)",
        "Biópsias de boca",
        "Prótese simples unitárias - (em resina e metal)",
        "Urgência e Emergências 24h",
        "Clareamento unitário de dente desvitalizado",
        "Documentação ortodôntica",
      ],
    },
    benefits: [
      "Cobertura ampliada + Documentação",
      "Manutenção e apoio diagnóstico",
      "Os mais modernos exames ortodônticos",
    ],
    moreBenefits: [
      [
        {
          icon: CalendarIcon,
          title: "Carências**",
          specialText: [
            ["24hs ------- Urgência e Emergência"],
            [
              "30 dias ---- Diagnóstico, Radiologia, Prevenção, Dentística, Periodontia, Odontopediatria e  DTM",
            ],
            [
              "180 dias --- Endodontia, Cirurgia, Documentação Ortodôntica e Prótese do Rol ANS.",
            ],
          ],
        },
        {
          icon: LocalIcon,
          title: "Ampla rede nacional",
          text: "Com + de 34 mil opções de atendimentos.",
        },
        {
          icon: EligibilityIcon,
          title: "Elegibilidade até 3º grau consanguíneo",
          text: "Cônjuge; companheiro(a); filhos(as); enteado(a) e tutelado(a); pai, mãe, irmãos, irmãs, avós, avôs, netos(as), tios(as), sobrinho(as), bisnetos(as), sogro(a), genro, nora, padrasto, madrasta e cunhado(a)",
        },
      ],
      [
        {
          icon: EligibilityIcon,
          title: "Para todas as idades",
          text: "",
        },
        {
          icon: NotIncludedIcon,
          title: "Não possui Coparticipação",
          text: "",
        },
        {
          icon: DiscountIcon,
          title: "Possui Reembolso",
          text: "",
        },
        {
          icon: DoctorIcon,
          title: "230 procedimentos, incluídos ROL ANS",
          text: "",
        },
        {
          icon: QuestionIcon,
          title: "Dúvidas? Consulte nossa FAQ",
          text: "",
        },
      ],
    ],
    moreAdvantages: [
      [
        {
          icon: DoctorIcon,
          title: "Descontos de até 70% em farmácias",
          text: "Medicamentos e itens de higiene bucal",
        },
        {
          icon: IncludedIcon,
          title: "SulAMais",
          text: "Clube de vantagens com descontos e benefícios exclusivos",
        },
        {
          icon: ToothIcon,
          title: "Aplicativo SulAmérica Odonto",
          text: "Carteirinha digital, rede credenciada, procedimentos cobertos, reembolso e muito mais.",
        },
        {
          icon: QuestionIcon,
          title: "Central de Atendimento 24h",
          text: "",
        },
      ],
    ],
    checkItems: [
      {
        text: "230 procedimentos",
        icon: "white_teeth.svg",
      },
      {
        text: "Documentação ortodôntica",
        icon: "blue_teeth.svg",
      },
      {
        text: "Cirurgias",
        icon: "blue_teeth.svg",
      },
      {
        text: "Extrações",
        icon: "blue_teeth.svg",
      },
    ],
    icons: [
      {
        text: "3º benefício mais desejado entre colaboradores",
        icon: MedalIcon,
      },
      {
        text: "Rede especializada e nacional",
        icon: BrazilIcon,
      },
      {
        text: "Sem carência",
        icon: CalendarIcon2,
      },
    ],
  },
  {
    id: 25441,
    codMonth: process.env.REACT_APP_environment === "master" ? 25427 : 25392,
    codYear: process.env.REACT_APP_environment === "master" ? 25428 : 25395,
    title: "Mais Clarear",
    subtitle: "Cobertura ampliada + clareamento",
    text: "O Mais Clarear possui a cobertura do Mais Doc e ainda tem clareamento dental e muito mais",
    fullPrice: null,
    fullPriceYear: null,
    price: null,
    decimalPrice: null,
    priceYear: null,
    decimalPriceYear: null,
    iof: null,
    registry: "Registro ANS: 494329235",
    registryDesc: "Mais Clarear Empresarial Rol Ampliado + Clareamento Conv.",
    bottomText:
      "**Isenção de carência para as adesões realizadas nos primeiros 30 dias após a contratação do plano, casamento, nascimento ou adoção. Para adesões realizadas após esse período, serão aplicadas as carências contratuais.",
    procedures: {
      count: 222,
      descriptions: [
        "Restaurações",
        "Radiografias",
        "Limpezas, prevenção e aplicação de flúor",
        "Tratamento de canal dentário",
        "Tratamento gengival (Todas as doenças associada a gengiva)",
        "Cirurgias e extrações (incluindo dente do Siso e inclusos)",
        "Biópsias de boca",
        "Prótese simples unitárias - (em resina e metal)",
        "Urgência e Emergências 24h",
        "Clareamento unitário de dente desvitalizado",
        "Clareamento caseiro (gel + placas)",
      ],
    },
    benefits: [
      "Cobertura ampliada + Clareamento",
      "Manutenção e beleza do seu sorriso",
      "Clareamento seguro e eficaz com moldeira",
    ],
    moreBenefits: [
      [
        {
          icon: CalendarIcon,
          title: "Carências**",
          specialText: [
            ["24hs ------ Urgência e Emergência"],
            [
              "30 dias --- Diagnóstico, Radiologia, Prevenção, Dentística, Periodontia, Odontopediatria e  DTM",
            ],
            [
              "180 dias -- Endodontia, Cirurgia, Documentação Ortodôntica e Prótese do Rol ANS.",
            ],
          ],
        },
        {
          icon: LocalIcon,
          title: "Ampla rede nacional",
          text: "Com + de 34 mil opções de atendimentos.",
        },
        {
          icon: EligibilityIcon,
          title: "Elegibilidade até 3º grau consanguíneo",
          text: "Cônjuge; companheiro(a); filhos(as); enteado(a) e tutelado(a); pai, mãe, irmãos, irmãs, avós, avôs, netos(as), tios(as), sobrinho(as), bisnetos(as), sogro(a), genro, nora, padrasto, madrasta e cunhado(a)",
        },
      ],
      [
        {
          icon: EligibilityIcon,
          title: "Para todas as idades",
          text: "",
        },
        {
          icon: NotIncludedIcon,
          title: "Não possui Coparticipação",
          text: "",
        },
        {
          icon: DiscountIcon,
          title: "Possui Reembolso",
          text: "",
        },
        {
          icon: DoctorIcon,
          title: "222 procedimentos, incluídos ROL ANS",
          text: "",
        },
        {
          icon: QuestionIcon,
          title: "Dúvidas? Consulte nossa FAQ",
          text: "",
        },
      ],
    ],
    moreAdvantages: [
      [
        {
          icon: DoctorIcon,
          title: "Descontos de até 70% em farmácias",
          text: "Medicamentos e itens de higiene bucal",
        },
        {
          icon: IncludedIcon,
          title: "SulAMais",
          text: "Clube de vantagens com descontos e benefícios exclusivos",
        },
        {
          icon: ToothIcon,
          title: "Aplicativo SulAmérica Odonto",
          text: "Carteirinha digital, rede credenciada, procedimentos cobertos, reembolso e muito mais.",
        },
        {
          icon: QuestionIcon,
          title: "Central de Atendimento 24h",
          text: "",
        },
      ],
    ],
    checkItems: [
      {
        text: "222 procedimentos",
        icon: "white_teeth.svg",
      },
      {
        text: "Clareamento convencional",
        icon: "blue_teeth.svg",
      },
      {
        text: "Clareamento de dente desvitalizado",
        icon: "blue_teeth.svg",
      },
      {
        text: "Limpeza e Extrações",
        icon: "blue_teeth.svg",
      },
    ],
    icons: [
      {
        text: "3º benefício mais desejado entre colaboradores",
        icon: MedalIcon,
      },
      {
        text: "Rede especializada e nacional",
        icon: BrazilIcon,
      },
      {
        text: "Sem carência",
        icon: CalendarIcon2,
      },
    ],
  },
  {
    id: 25442,
    codMonth: process.env.REACT_APP_environment === "master" ? 25429 : 25393,
    codYear: process.env.REACT_APP_environment === "master" ? 25430 : 25396,
    title: "Mais Orto",
    subtitle: "Cobertura ampliada + ortodontia",
    text: "Plano anual sem carência",
    fullPrice: null,
    fullPriceYear: null,
    price: null,
    decimalPrice: null,
    priceYear: null,
    decimalPriceYear: null,
    iof: null,
    registry: "Registro ANS: 494861231",
    registryDesc: "Mais Orto Empresarial Rol Ampliado + Ortodontia",
    procedures: {
      count: 306,
      descriptions: [
        "Urgência e Emergências 24h",
        "Restaurações",
        "Radiografias",
        "Tratamento de canal dentário",
        "Tratamento gengival  (Todas as doenças)",
        "Cirurgias e extrações (incluindo Siso e inclusos)",
        "Biópsias de boca",
        "Prótese simples unitárias - (em resina e metal)",
        "Clareamento unitário de dente desvitalizado",
        "Tratamento documentação e radiografias ortodônticas",
        "Instalação e manutenção de aparelho fixo metálico",
      ],
    },
    benefits: [
      "Cobertura ampliada + Documentação + Ortodontia",
      "Manutenção e tratamento ortodôntico completo",
      "Aparelho fixo",
    ],
    moreBenefits: [
      [
        {
          icon: CalendarIcon,
          title: "Carências**",
          specialText: [
            ["24hs ------ Urgência e Emergência"],
            [
              "30 dias --- Diagnóstico, Radiologia, Prevenção, Dentística, Periodontia, Odontopediatria e  DTM",
            ],
            [
              "180 dias -- Endodontia, Cirurgia, Ortodontia e Prótese do Rol ANS.",
            ],
          ],
        },
        {
          icon: LocalIcon,
          title: "Ampla rede nacional",
          text: "Com + de 34 mil opções de atendimentos.",
        },
        {
          icon: EligibilityIcon,
          title: "Elegibilidade até 3º grau consanguíneo",
          text: "Cônjuge; companheiro(a); filhos(as); enteado(a) e tutelado(a); pai, mãe, irmãos, irmãs, avós, avôs, netos(as), tios(as), sobrinho(as), bisnetos(as), sogro(a), genro, nora, padrasto, madrasta e cunhado(a)",
        },
      ],
      [
        {
          icon: EligibilityIcon,
          title: "Para todas as idades",
          text: "",
        },
        {
          icon: NotIncludedIcon,
          title: "Não possui Coparticipação",
          text: "",
        },
        {
          icon: DiscountIcon,
          title: "Possui Reembolso",
          text: "",
        },
        {
          icon: DoctorIcon,
          title: "306 procedimentos, incluídos ROL ANS",
          text: "",
        },
        {
          icon: QuestionIcon,
          title: "Dúvidas? Consulte nossa FAQ",
          text: "",
        },
      ],
    ],
    moreAdvantages: [
      [
        {
          icon: DoctorIcon,
          title: "Descontos de até 70% em farmácias",
          text: "Medicamentos e itens de higiene bucal",
        },
        {
          icon: IncludedIcon,
          title: "SulAMais",
          text: "Clube de vantagens com descontos e benefícios exclusivos",
        },
        {
          icon: ToothIcon,
          title: "Aplicativo SulAmérica Odonto",
          text: "Carteirinha digital, rede credenciada, procedimentos cobertos, reembolso e muito mais.",
        },
        {
          icon: QuestionIcon,
          title: "Central de Atendimento 24h",
          text: "",
        },
      ],
    ],
    checkItems: [
      {
        text: "306 procedimentos",
        icon: "white_teeth.svg",
      },
      {
        text: "Documentação ortodôntica",
        icon: "blue_teeth.svg",
      },
      {
        text: "Tratamento ortodôntico completo",
        icon: "blue_teeth.svg",
      },
      {
        text: "Instalação e Manutenção de Aparelho fixo metálico",
        icon: "blue_teeth.svg",
      },
    ],
    icons: [
      {
        text: "3º benefício mais desejado entre colaboradores",
        icon: MedalIcon,
      },
      {
        text: "Rede especializada e nacional",
        icon: BrazilIcon,
      },
      {
        text: "Sem carência",
        icon: CalendarIcon2,
      },
    ],
  },
  {
    id: 25443,
    codMonth: process.env.REACT_APP_environment === "master" ? 25429 : 25393,
    codYear: process.env.REACT_APP_environment === "master" ? 25430 : 25396,
    title: "Mais Pro",
    subtitle: "Cobertura ampliada + Próteses",
    text: "Plano anual sem carência",
    fullPrice: null,
    fullPriceYear: null,
    price: null,
    decimalPrice: null,
    priceYear: null,
    decimalPriceYear: null,
    iof: null,
    registry: "Registro ANS: 494862239",
    registryDesc: "Mais Pro Empresarial Rol Ampliado + Protese",
    procedures: {
      count: 275,
      descriptions: [
        "Urgência e Emergências 24h",
        "Restaurações",
        "Radiografias",
        "Limpezas, prevenção e aplicação de flúor",
        "Tratamento de canal dentário",
        "Tratamento gengival  (Todas as doenças)",
        "Cirurgias e extrações (incluindo Siso e inclusos)",
        "Biópsias de boca",
        "Prótese simples unitárias - (em resina e metal)",
        "Clareamento unitário de dente desvitalizado",
        "Próteses (cerâmicas, removíveis e dentaduras)",
      ],
    },
    benefits: [
      "Cobertura ampliada + Documentação + Ortodontia",
      "Manutenção e tratamento ortodôntico completo",
      "Aparelho fixo",
    ],
    moreBenefits: [
      [
        {
          icon: CalendarIcon,
          title: "Carências**",
          specialText: [
            ["24hs ------ Urgência e Emergência"],
            [
              "30 dias --- Diagnóstico, Radiologia, Prevenção, Dentística, Periodontia, Odontopediatria e  DTM",
            ],
            [
              "180 dias -- Endodontia, Cirurgia e Próteses.",
            ],
          ],
        },
        {
          icon: LocalIcon,
          title: "Ampla rede nacional",
          text: "Com + de 34 mil opções de atendimentos.",
        },
        {
          icon: EligibilityIcon,
          title: "Elegibilidade até 3º grau consanguíneo",
          text: "Cônjuge; companheiro(a); filhos(as); enteado(a) e tutelado(a); pai, mãe, irmãos, irmãs, avós, avôs, netos(as), tios(as), sobrinho(as), bisnetos(as), sogro(a), genro, nora, padrasto, madrasta e cunhado(a)",
        },
      ],
      [
        {
          icon: EligibilityIcon,
          title: "Para todas as idades",
          text: "",
        },
        {
          icon: NotIncludedIcon,
          title: "Não possui Coparticipação",
          text: "",
        },
        {
          icon: DiscountIcon,
          title: "Possui Reembolso",
          text: "",
        },
        {
          icon: DoctorIcon,
          title: "275 procedimentos, incluídos ROL ANS",
          text: "",
        },
        {
          icon: QuestionIcon,
          title: "Dúvidas? Consulte nossa FAQ",
          text: "",
        },
      ],
    ],
    moreAdvantages: [
      [
        {
          icon: DoctorIcon,
          title: "Descontos de até 70% em farmácias",
          text: "Medicamentos e itens de higiene bucal",
        },
        {
          icon: IncludedIcon,
          title: "SulAMais",
          text: "Clube de vantagens com descontos e benefícios exclusivos",
        },
        {
          icon: ToothIcon,
          title: "Aplicativo SulAmérica Odonto",
          text: "Carteirinha digital, rede credenciada, procedimentos cobertos, reembolso e muito mais.",
        },
        {
          icon: QuestionIcon,
          title: "Central de Atendimento 24h",
          text: "",
        },
      ],
    ],
    checkItems: [
      {
        text: "275 procedimentos",
        icon: "white_teeth.svg",
      },
      {
        text: "Próteses simples e unitária",
        icon: "blue_teeth.svg",
      },
      {
        text: "Próteses cerâmicas e removíveis",
        icon: "blue_teeth.svg",
      },
      {
        text: "Dentaduras",
        icon: "blue_teeth.svg",
      },
    ],
    icons: [
      {
        text: "3º benefício mais desejado entre colaboradores",
        icon: MedalIcon,
      },
      {
        text: "Rede especializada e nacional",
        icon: BrazilIcon,
      },
      {
        text: "Sem carência",
        icon: CalendarIcon2,
      },
    ],
  },
  {
    id: 25444,
    codMonth: process.env.REACT_APP_environment === "master" ? 25429 : 25393,
    codYear: process.env.REACT_APP_environment === "master" ? 25430 : 25396,
    title: "Mais Amplo",
    subtitle: "Cobertura ampliada + Orto + Próteses + Clareamento",
    text: "Cobertura ampliada + Orto + Próteses + Clareamento",
    fullPrice: null,
    fullPriceYear: null,
    price: null,
    decimalPrice: null,
    priceYear: null,
    decimalPriceYear: null,
    iof: null,
    registry: "Registro ANS: 494863237",
    registryDesc: "Mais Amplo Emp. Rol Ampl. + Orto + Protese + Clarea Conv.",
    procedures: {
      count: 353,
      descriptions: [
        "Urgência e Emergências 24h",
        "Restaurações",
        "Radiografias",
        "Limpezas, prevenção e aplicação de flúor",
        "Tratamento de canal dentário",
        "Tratamento gengival  (Todas as doenças)",
        "Cirurgias e extrações (incluindo Siso e inclusos)",
        "Biópsias de boca",
        "Prótese simples unitárias - (em resina e metal)",
        "Clareamento unitário de dente desvitalizado",
        "Tratamento documentação e radiografias ortodônticas",
        "Instalação e manutenção de aparelho fixo metálico",
        "Próteses (cerâmicas, removíveis e dentaduras)",
      ],
    },
    benefits: [
      "Cobertura ampliada + Documentação + Ortodontia",
      "Manutenção e tratamento ortodôntico completo",
      "Aparelho fixo",
    ],
    moreBenefits: [
      [
        {
          icon: CalendarIcon,
          title: "Carências**",
          specialText: [
            ["24hs ------ Urgência e Emergência"],
            [
              "30 dias --- Diagnóstico, Radiologia, Prevenção, Dentística, Periodontia, Odontopediatria e  DTM",
            ],
            [
              "180 dias -- Endodontia, Cirurgia, Ortodontia, Clareamento convencional e Próteses.",
            ],
          ],
        },
        {
          icon: LocalIcon,
          title: "Ampla rede nacional",
          text: "Com + de 34 mil opções de atendimentos.",
        },
        {
          icon: EligibilityIcon,
          title: "Elegibilidade até 3º grau consanguíneo",
          text: "Cônjuge; companheiro(a); filhos(as); enteado(a) e tutelado(a); pai, mãe, irmãos, irmãs, avós, avôs, netos(as), tios(as), sobrinho(as), bisnetos(as), sogro(a), genro, nora, padrasto, madrasta e cunhado(a)",
        },
      ],
      [
        {
          icon: EligibilityIcon,
          title: "Para todas as idades",
          text: "",
        },
        {
          icon: NotIncludedIcon,
          title: "Não possui Coparticipação",
          text: "",
        },
        {
          icon: DiscountIcon,
          title: "Possui Reembolso",
          text: "",
        },
        {
          icon: DoctorIcon,
          title: "353 procedimentos, incluídos ROL ANS",
          text: "",
        },
        {
          icon: QuestionIcon,
          title: "Dúvidas? Consulte nossa FAQ",
          text: "",
        },
      ],
    ],
    moreAdvantages: [
      [
        {
          icon: DoctorIcon,
          title: "Descontos de até 70% em farmácias",
          text: "Medicamentos e itens de higiene bucal",
        },
        {
          icon: IncludedIcon,
          title: "SulAMais",
          text: "Clube de vantagens com descontos e benefícios exclusivos",
        },
        {
          icon: ToothIcon,
          title: "Aplicativo SulAmérica Odonto",
          text: "Carteirinha digital, rede credenciada, procedimentos cobertos, reembolso e muito mais.",
        },
        {
          icon: QuestionIcon,
          title: "Central de Atendimento 24h",
          text: "",
        },
      ],
    ],
    checkItems: [
      {
        text: "353 procedimentos",
        icon: "white_teeth.svg",
      },
      {
        text: "Tratamento ortodôntico",
        icon: "blue_teeth.svg",
      },
      {
        text: "Próteses (cerâmicas, removíveis e dentaduras)",
        icon: "blue_teeth.svg",
      },
      {
        text: "Clareamento convencional",
        icon: "blue_teeth.svg",
      },
    ],
    icons: [
      {
        text: "3º benefício mais desejado entre colaboradores",
        icon: MedalIcon,
      },
      {
        text: "Rede especializada e nacional",
        icon: BrazilIcon,
      },
      {
        text: "Sem carência",
        icon: CalendarIcon2,
      },
    ],
  },
  {
    id: 25560, //25445,
    codMonth: process.env.REACT_APP_environment === "master" ? 25429 : 25393,
    codYear: process.env.REACT_APP_environment === "master" ? 25430 : 25396,
    title: "Premium",
    subtitle:
      "Cobertura ampliada + Orto + Próteses + Implante + Clareamento a laser",
    text: "Cobertura ampliada + Orto + Próteses + Implante + Clareamento a laser",
    fullPrice: null,
    fullPriceYear: null,
    price: null,
    decimalPrice: null,
    priceYear: null,
    decimalPriceYear: null,
    iof: null,
    registry: "Registro ANS: 494864235",
    registryDesc: " Premium Emp. Rol Amp. + Orto + Protese + Implante",
    procedures: {
      count: 403,
      descriptions: [
        "Urgência e Emergências 24h",
        "Restaurações",
        "Radiografias",
        "Limpezas, prevenção e aplicação de flúor",
        "Tratamento de canal dentário",
        "Tratamento gengival  (Todas as doenças)",
        "Cirurgias e extrações (incluindo Siso e inclusos)",
        "Clareamento a laser",
        "Tratamento documentação e radiografias ortodônticas",
        "Instalação e manutenção de aparelho fixo metálico",
        "Coroa total metal ou cerâmica sobre implante",
        "Próteses (cerâmicas, removíveis e dentaduras)",
      ],
    },
    benefits: [
      "Cobertura ampliada + Documentação + Ortodontia",
      "Manutenção e tratamento ortodôntico completo",
      "Aparelho fixo",
    ],
    moreBenefits: [
      [
        {
          icon: CalendarIcon,
          title: "Carências**",
          specialText: [
            ["24hs ------ Urgência e Emergência"],
            [
              "30 dias --- Diagnóstico, Radiologia, Prevenção, Dentística, Periodontia, Odontopediatria e DTM",
            ],
            [
              "180 dias -- Endodontia, Cirurgia, Ortodontia, Clareamento convencional, Clareamento a laser Próteses e Implante",
            ],
          ],
        },
        {
          icon: LocalIcon,
          title: "Ampla rede nacional",
          text: "Com + de 34 mil opções de atendimentos.",
        },
        {
          icon: EligibilityIcon,
          title: "Elegibilidade até 3º grau consanguíneo",
          text: "Cônjuge; companheiro(a); filhos(as); enteado(a) e tutelado(a); pai, mãe, irmãos, irmãs, avós, avôs, netos(as), tios(as), sobrinho(as), bisnetos(as), sogro(a), genro, nora, padrasto, madrasta e cunhado(a)",
        },
      ],
      [
        {
          icon: EligibilityIcon,
          title: "Para todas as idades",
          text: "",
        },
        {
          icon: NotIncludedIcon,
          title: "Não possui Coparticipação",
          text: "",
        },
        {
          icon: DiscountIcon,
          title: "Possui Reembolso",
          text: "",
        },
        {
          icon: DoctorIcon,
          title: "403 procedimentos, incluídos ROL ANS",
          text: "",
        },
        {
          icon: QuestionIcon,
          title: "Dúvidas? Consulte nossa FAQ",
          text: "",
        },
      ],
    ],
    moreAdvantages: [
      [
        {
          icon: DoctorIcon,
          title: "Descontos de até 70% em farmácias",
          text: "Medicamentos e itens de higiene bucal",
        },
        {
          icon: IncludedIcon,
          title: "SulAMais",
          text: "Clube de vantagens com descontos e benefícios exclusivos",
        },
        {
          icon: ToothIcon,
          title: "Aplicativo SulAmérica Odonto",
          text: "Carteirinha digital, rede credenciada, procedimentos cobertos, reembolso e muito mais.",
        },
        {
          icon: QuestionIcon,
          title: "Central de Atendimento 24h",
          text: "",
        },
      ],
    ],
    checkItems: [
      {
        text: "403 procedimentos",
        icon: "white_teeth.svg",
      },
      {
        text: "Próteses (cerâmicas, removíveis e dentaduras)",
        icon: "blue_teeth.svg",
      },
      {
        text: "Implante ósseo integrado",
        icon: "blue_teeth.svg",
      },
      {
        text: "Clareamento a laser",
        icon: "blue_teeth.svg",
      },
    ],
    icons: [
      {
        text: "3º benefício mais desejado entre colaboradores",
        icon: MedalIcon,
      },
      {
        text: "Rede especializada e nacional",
        icon: BrazilIcon,
      },
      {
        text: "Sem carência",
        icon: CalendarIcon2,
      },
    ],
  },
];

export default productsData;
