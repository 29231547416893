import axios from "axios";
import { encode } from "base-64";
import moment from "moment";
import AxiosRetry from "axios-retry";

interface PostOptions {
  body: any;
}

interface CPFData {
  cpfTitular: string;
  cpfRespFinanc: string;
}

async function getToken() {
  const tokenType =
    process.env.REACT_APP_environment === "master" ? "mToken" : "sToken";

  try {
    let minutesDiff = 0;
    let currentToken = sessionStorage.getItem(tokenType)
      ? JSON.parse(sessionStorage.getItem(tokenType) ?? "")
      : false;
    if (currentToken) {
      const saveHour = moment(currentToken.created_at);
      const currentHour = moment();
      minutesDiff = currentHour.diff(saveHour, "minutes");
      if (minutesDiff <= 24) {
        return currentToken.token;
      } else {
        currentToken = null;
      }
    }

    if (!currentToken) {
      const authToken = encode(
        `${process.env.REACT_APP_SENSEDIA_USER_NAME}:${process.env.REACT_APP_SENSEDIA_USER_PASSWORD}`
      );

      const data = new URLSearchParams();
      data.append("grant_type", "password");
      data.append("username", "OdontoDayBff");
      data.append("password", "29@LsILu3");

      const response = await axios.post(
        `${process.env.REACT_APP_SENSEDIA_API_ACCESS_TOKEN}`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Basic ${authToken}`,
            "tipo-usuario": "UG",
            //Cookie: "CookieConsentPolicy=0:1; LSKey-c$CookieConsentPolicy=0:1",
          },
        }
      );
      const currentHour = moment();

      sessionStorage.setItem(
        tokenType,
        JSON.stringify({
          token: response.data.access_token,
          created_at: currentHour.format("YYYY-MM-DD HH:mm:ss"),
        })
      );

      return response.data.access_token;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const instance = axios.create();

instance.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    const { headers } = await getHeaders();
    config.headers = { ...headers, ...config.headers };
    if (token) {
      config.headers = token
        ? {
            ...config.headers,
            Authorization: `Bearer ${token}`,
            client_id: `${process.env.REACT_APP_SENSEDIA_USER_NAME}`,
          }
        : { ...config.headers };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AxiosRetry(instance, {
  retries: 5,
  retryDelay: (retryCount) => {
    return retryCount * 6000;
  },
  retryCondition: (error) => {
    return error.response?.status === 504;
  },
});

const postRequest = async (
  url: string,
  options: PostOptions,
  cpf: CPFData | null = null,
  isFile = false
) => {
  const { body } = options;

  try {
    const headers = await getHeaders(isFile, cpf);
    const response = await instance.post(
      `${process.env.REACT_APP_BFF}${url}`,
      body,
      headers
    );

    return response;
  } catch (exception: any) {
    return exception.response;
  }
};

const putRequest = async (
  url: string,
  options: PostOptions,
  cpf: CPFData | null = null,
  isFile = false
) => {
  const { body } = options;

  try {
    const headers = await getHeaders(isFile, cpf);
    const response = await instance.put(
      `${process.env.REACT_APP_BFF}${url}`,
      body,
      headers
    );

    return response;
  } catch (exception: any) {
    return exception.response;
  }
};

const getRequest = async (
  url: string,
  cpf: CPFData | null = null,
  isFile = false
) => {
  try {
    const headers = await getHeaders(isFile, cpf);
    const response = await instance.get(
      `${process.env.REACT_APP_BFF}${url}`,
      headers
    );

    return response;
  } catch (exception: any) {
    return exception.response;
  }
};

const deleteRequest = async (url: string) => {
  try {
    const headers = await getHeaders();
    const response = await instance.delete(
      `${process.env.REACT_APP_BFF}${url}`,
      headers
    );

    return response;
  } catch (exception: any) {
    return exception.response;
  }
};

const getHeaders = async (isFile = false, cpfData: CPFData | null = null) => {
  const token = sessionStorage.getItem("token");

  const headers = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      autho_api_aws: `Bearer ${token}`,
      cpf: cpfData ? cpfData.cpfTitular : "",
      cpf_resp_financ: cpfData ? cpfData.cpfRespFinanc : "",
    },
    //responseType: isFile ? ("blob" as "blob") : ("text" as "text"),
  };

  return headers;
};

/*
const handleException = (
  exception: any,
  errorMessage: string,
  onError: (message: string, error: string[] | never[], status: STATUS) => void
) => {
  console.log(errorMessage);
  if (
    exception.response &&
    exception.response.data.messages &&
    exception.response.data.messages.length
  ) {
    return onError(errorMessage, [], "error");
  }
  return onError("Serviço indisponível no momento.", [], "error");
};
*/

export { postRequest, putRequest, deleteRequest, getRequest };
