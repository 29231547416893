import { Box, Button, Link, Menu, MenuItem, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import styled from "styled-components";

import { FlexGrid } from "../../grid/components/FlexGrid";
import { HashLink } from "react-router-hash-link";
import AvatarImage from "../../../../assets/images/avatar-icon.svg";

type ItemProp = {
  $last?: boolean;
};

export const StyledBox = styled(Box)`
  && {
    position: relative;
    transform: scale(1);
    background-color: #ffffff;
    box-shadow: 0px 2px 5px rgba(115, 137, 169, 0.25);
    border-radius: 0px;

    padding: 37px 63px;

    @media (max-width: 475px) {
      padding: 35px 20px;
    }
  }
`;

export const LogoBox = styled(Box)`
  cursor: pointer;
`;

export const LogoImage = styled.img`
  && {
    height: 37px;
    width: 150px;

    @media (max-width: 475px) {
      height: 30px;
      width: 120px;
    }
  }
`;

export const AvatarIcon = styled(Button)`
  background-image: url(${AvatarImage});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;

  && {
    @media (max-width: 475px) {
      height: 24px;
      width: 24px;
    }
  }
`;

export const AvatarName = styled(Typography)`
  && {
  }
`;

export const AvatarLogoutButton = styled.a`
  && {
    font-weight: 200;
    cursor: pointer;
  }
`;

export const MenuIcon = styled.img`
  height: 12px;
  width: 18px;
`;

export const AvatarContainer = styled(Box)`
  && {
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 40px;
  }
`;
export const IconLink = styled(Link)`
  && {
    cursor: pointer;
  }
`;

export const Navbar = styled(FlexGrid)`
  align-items: center;

  && {
    @media (max-width: 475px) {
      display: none;
    }
  }
`;

export const Navlink = styled(HashLink)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    text-decoration: none;

    color: #001e64;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const NavDivider = styled(Divider)`
  && {
    height: 25px;
    width: 1px;

    margin: 0 18px;
    text-align: center;
    align-items: center;

    border: none;
    background-color: #323232;
  }
`;

export const MenuButton = styled(Button)`
  && {
    display: none;

    margin-left: 10px;

    @media (max-width: 475px) {
      display: flex;
    }
  }
`;

export const StyledMenu = styled(Menu)`
  && > .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    padding-top: 10px;
  }
`;

export const StyledMenuItem = styled(MenuItem)<ItemProp>`
  ${(props) => `
    && {
      margin-bottom: ${props.$last ? "16px" : "32px"};
    }
  `}
`;

export const MenuLink = styled(HashLink)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    text-decoration: none;

    color: #323232;
  }
`;

export const CorrectorBar = styled(Box)`
  && {
    width: 100%;
    padding: 10px 0;
    background-color: #146efa;
    color: #ffffff;
    text-align: center;
  }
`;
export const CorrectorBarText = styled(Typography)`
  && {
    font-weight: 600;
  }
`;
