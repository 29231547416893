import { loadLocalImage } from "../../../../../../../utils";
import {
  CustomCard,
  CustomCardHeader,
  CustomCardText,
  CustomCardTextContainer,
  CustomIcon,
} from "../styles/DesktopCardStyles";
import { CardProps } from "../types";

export const AdvantageDesktopCard = ({ text, icon, hoverText }: CardProps) => {
  return (
    <CustomCard>
      <CustomCardHeader>
        <CustomIcon src={loadLocalImage(icon, "advantages")} />
      </CustomCardHeader>
      <CustomCardTextContainer>
        <CustomCardText>{text}</CustomCardText>
      </CustomCardTextContainer>
    </CustomCard>
  );
};
