import { SCCButton } from "../styles/ServiceChannelsStyles";
import { SCCContainer, SCCItem } from "../styles/ServiceChannelsStyles";

export const ServiceChannelsBox = () => (
  <SCCContainer>
    <SCCItem container>
      <SCCItem item xs={12}>
        <SCCButton
          onClick={() =>
            window.open(
              "https://portal.sulamericaseguros.com.br/canaisdeatendimento/",
              "_blank"
            )
          }
          variant="outlined"
        >
          Canais de Atendimento
        </SCCButton>
      </SCCItem>
      <SCCItem item xs={12}>
        <SCCButton
          onClick={() =>
            window.open(
              "https://portal.sulamericaseguros.com.br/privacidade/login",
              "_blank"
            )
          }
          variant="outlined"
        >
          Portal de privacidade
        </SCCButton>
      </SCCItem>
    </SCCItem>
  </SCCContainer>
);
