import { Box, Grid } from "@mui/material";
import Button from "../../../../common/button/components/Button";
import { FlexGrid } from "../../../../common/grid/components/FlexGrid";

import Skeleton from "@mui/material/Skeleton";

import {
  Block,
  BoxButton,
  BoxPrice,
  BoxSubtitle,
  BoxTitle,
  BoxValue,
  Card,
  CheckTitle,
  DesktopVersion,
  HighlightBlock,
  HighlightText,
  Observation,
  PriceText,
  ProductLink,
  TeethIcon,
} from "../styles/ProductsStyles";
import { ProductProps } from "./Products";
import { loadLocalImage } from "../../../../../utils";
import useGoogleTagManager from "../../../../../hooks/useGTM";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";
import "swiper/modules/free-mode/free-mode.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { FreeMode, Pagination, Navigation } from "swiper";

const ProductsDesktop = (props: ProductProps) => {
  const { products, navigateOut } = props;
  const { interactionEvent } = useGoogleTagManager();

  const pagination = {
    clickable: true,
    renderBullet: (index: number, className: string) =>
      `<span class="${className}"></span>`,
  };

  const gtmHandleClick = (planName: string, id: number) => {
    interactionEvent({
      event_action: "clicou",
      event_category: "catalogo_planos_home",
      event_label: "conheca_ja",
      event_name: `ver_detalhes_do_produto`,
      select_item: `${planName}`,
    });
    props.navigate(id);
  };

  return (
    <DesktopVersion container>
      {products.length <= 5 &&
        products?.map((data: any) => (
          <FlexGrid
            item
            sm={products.length === 5 ? 2 : 3}
            sx={{ minWidth: "300px" }}
            key={data.title}
          >
            <Card>
              <Grid padding={1}>
                <BoxTitle>{data.title}</BoxTitle>
                <BoxValue>
                  <BoxSubtitle>{data.subtitle}</BoxSubtitle>
                  <Grid
                    container
                    flexDirection={"column"}
                    justifyContent="flex-start"
                    sx={{ marginTop: "30px" }}
                  >
                    {data.price && (
                      <Grid container alignItems={"flex-end"}>
                        <Grid item xs={8}>
                          <BoxPrice>
                            R$ <span>{data.price}</span>,{data.decimalPrice}*
                          </BoxPrice>
                        </Grid>
                        <Grid item xs={4} marginBottom={0.5}>
                          <PriceText>mensais{"\n"}por pessoa</PriceText>
                        </Grid>
                      </Grid>
                    )}
                    {!data.price && <Skeleton width="100%" />}
                  </Grid>
                </BoxValue>
              </Grid>
              {data.checkItems.map((item: any, index: number) => (
                <Grid key={index}>
                  {index === 0 ? (
                    <HighlightBlock>
                      <TeethIcon
                        src={loadLocalImage(item.icon, "")}
                        alt="teeth icon"
                      />
                      <HighlightText>{item.text}</HighlightText>
                    </HighlightBlock>
                  ) : (
                    <Block>
                      <TeethIcon
                        src={loadLocalImage(item.icon, "")}
                        alt="teeth icon"
                      />
                      <Box>
                        <CheckTitle>{item.text}</CheckTitle>
                      </Box>
                    </Block>
                  )}
                </Grid>
              ))}
              <BoxButton>
                <Button
                  type="button"
                  variant="contained"
                  background="fill"
                  width="232px"
                  bgcolor="#FF5000"
                  onClick={navigateOut}
                >
                  Contrate Agora
                </Button>
              </BoxButton>
              <FlexGrid container sx={{ marginTop: "15px" }}>
                <ProductLink
                  onClick={() => gtmHandleClick(data.title, data.id)}
                >
                  Clique aqui e saiba mais
                </ProductLink>
              </FlexGrid>
              <FlexGrid container>
                <Observation>
                  *Acréscimo de IOF (
                  {data.iof !== null ? (
                    data.iof.toFixed(2).replace(".", ",")
                  ) : (
                    <Skeleton sx={{ display: "inline-flex", width: "10px" }} />
                  )}
                  %)
                </Observation>
              </FlexGrid>
            </Card>
          </FlexGrid>
        ))}
      {products.length > 5 && (
        <Swiper
          slidesPerView={4.2}
          spaceBetween={4}
          freeMode={true}
          grabCursor={true}
          keyboard={true}
          navigation={true}
          pagination={pagination}
          modules={[FreeMode, Pagination, Navigation]}
        >
          {products?.map((data: any) => (
            <SwiperSlide key={data.title}>
              <FlexGrid item sx={{ minWidth: "300px" }} key={data.title}>
                <Card sx={{ marginBottom: "80px" }}>
                  <Grid padding={1}>
                    <BoxTitle>{data.title}</BoxTitle>
                    <BoxValue>
                      <BoxSubtitle>{data.subtitle}</BoxSubtitle>
                      {data.price && (
                        <Grid
                          container
                          flexDirection={"column"}
                          justifyContent="flex-start"
                          sx={{ marginTop: "30px" }}
                        >
                          <Grid container alignItems={"flex-end"}>
                            <FlexGrid item xs={8.5}>
                              <BoxPrice>
                                R$ <span>{data.price}</span>,{data.decimalPrice}
                                *
                              </BoxPrice>
                            </FlexGrid>
                            <Grid item xs={3.5} marginBottom={0.5}>
                              <PriceText>mensais{"\n"}por pessoa</PriceText>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {!data.price && <Skeleton width="100%" />}
                    </BoxValue>
                  </Grid>
                  {data.checkItems.map((item: any, index: number) => (
                    <Grid key={index}>
                      {index === 0 ? (
                        <HighlightBlock>
                          <TeethIcon
                            src={loadLocalImage(item.icon, "")}
                            alt="teeth icon"
                          />
                          <HighlightText>{item.text}</HighlightText>
                        </HighlightBlock>
                      ) : (
                        <Block>
                          <TeethIcon
                            src={loadLocalImage(item.icon, "")}
                            alt="teeth icon"
                          />
                          <Box>
                            <CheckTitle>{item.text}</CheckTitle>
                          </Box>
                        </Block>
                      )}
                    </Grid>
                  ))}
                  <BoxButton>
                    <Button
                      type="button"
                      variant="contained"
                      background="fill"
                      width="232px"
                      bgcolor="#FF5000"
                      onClick={navigateOut}
                    >
                      Contrate Agora
                    </Button>
                  </BoxButton>
                  <FlexGrid container sx={{ marginTop: "15px" }}>
                    <ProductLink
                      onClick={() => gtmHandleClick(data.title, data.id)}
                    >
                      Clique aqui e saiba mais
                    </ProductLink>
                  </FlexGrid>
                  <FlexGrid container>
                    <Observation>
                      *Acréscimo de IOF (
                      {data.iof !== null ? (
                        data.iof.toFixed(2).replace(".", ",")
                      ) : (
                        <Skeleton
                          sx={{ display: "inline-flex", width: "10px" }}
                        />
                      )}
                      %)
                    </Observation>
                  </FlexGrid>
                </Card>
              </FlexGrid>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </DesktopVersion>
  );
};

export default ProductsDesktop;
