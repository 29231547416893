import {
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import {
  CustomAccordion,
  Title,
  Wrapper,
  CustomContainer,
  MainTitle,
} from "../styles/CommonQuestionsStyles";
import AddIcon from "@mui/icons-material/Add";
import { Remove } from "@mui/icons-material";
import { useEffect, useState } from "react";
import questionsData from "../data/questions.json";
import useGoogleTagManager from "../../../../../hooks/useGTM";

interface IRequestData {
  question: string;
  answer: string;
}

export const CommonQuestions = () => {
  const { interactionEvent } = useGoogleTagManager();

  const [expanded, setExpanded] = useState<string | false>(false);
  const [data, setData] = useState<IRequestData[]>([]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const gtmHandleClick = (texto: string) => {
    interactionEvent({
      event_action: "clicou",
      event_category: "faq",
      event_label: `${texto}`,
      event_name: `duvidas_frequentes`,
    });
  };

  useEffect(() => {
    const { questions } = questionsData;
    setData(questions);
  }, []);

  return (
    <Wrapper id="common-questions" data-testid="common-questions">
      <CustomContainer>
        <MainTitle id="duvidas" variant="h1">
          Tire suas dúvidas
        </MainTitle>
        {data.map(({ question, answer }, index) => (
          <CustomAccordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            onClick={() => gtmHandleClick(answer)}
            key={`panel${index}`}
          >
            <AccordionSummary
              expandIcon={
                expanded === `panel${index}` ? <Remove /> : <AddIcon />
              }
              aria-controls="panel1bh-content"
              id={`panel${index}bh-header`}
            >
              <Title variant="h6" $isexpanded={expanded === `panel${index}`}>
                {question}
              </Title>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ whiteSpace: "pre-wrap" }}>{answer}</Typography>
            </AccordionDetails>
          </CustomAccordion>
        ))}
      </CustomContainer>
    </Wrapper>
  );
};
