import BannerImage from "../../../assets/images/banner-home.png";
import {
  DesktopVersion,
  Image,
  MobileVersion,
  StyledBox,
} from "../styles/BannerStyles";

const Banner = () => {
  return (
    <StyledBox id="banner">
      <DesktopVersion>
        <Image src={BannerImage} alt="banner" />
      </DesktopVersion>
      <MobileVersion />
    </StyledBox>
  );
};

export default Banner;
