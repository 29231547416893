import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Box, Grid } from "@mui/material";

export const StyledBox = styled(Box)`
  background-color: #f5f5f5;
`;

export const CustomContainer = styled(Container)`
  && {
    position: relative;
    padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: 720px) {
      padding: 20px 15px 15px 15px;
    }
  }
`;

export const TitleContainer = styled(Grid)`
  && {
    position: relative;
    @media screen and (max-width: 480px) {
      top: 45px;
    }

    @media screen and (max-width: 330px) {
      top: 25px;
    }
  }
`;

export const Title = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 53px;

    text-align: center;
    margin-bottom: 20px;

    color: #0b234c;

    @media screen and (max-width: 768px) {
      font-size: 2rem;
    }

    @media screen and (max-width: 425px) {
      font-size: 1.6rem;
    }
  }
`;

export const SubTitle = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;

    text-align: center;

    color: #0b234c;

    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
    }

    @media screen and (max-width: 425px) {
      font-size: 0.8rem;
    }
  }
`;

export const Observation = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;

    text-align: center;
    padding-bottom: 18px;

    color: #0b234c;
  }
`;
