import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import ProductsDesktop from "./ProductsDesktop";
import ProductsTablet from "./ProductsTablet";
import ProductsMobile from "./ProductsMobile";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";

import productsData from "../../../definitions/ProductsData";
import { getProducts } from "../../../../../gateway/requests/ProductsRequests";

import useStores from "../../../../../hooks/useStores";
import { getQueryUrlParam, setQueryParams } from "../../../../../utils";

export type ProductProps = {
  products: any;
  navigate: (id: number) => void;
  navigateOut: () => void;
};

const Products = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { productStore, correctorAndProducerStore } = useStores();
  const [products, setProducts] = useState<any>(productsData);

  const defineProducts = async () => {
    let newProducts = null;
    const response = await getProducts("430");

    if (response.status === 200 || response.status === 201) {
      const planos = response.data.retorno;

      newProducts = productsData.map((data: any) => {
        const plano = planos.find(
          (planoData: any) => planoData.codigo_plano === data.id
        );
        const splitPriceMonth = plano?.valor_preco_base
          ? plano?.valor_preco_base.toFixed(2).split(".")
          : "0.00";

        return {
          ...data,
          fullPrice: plano.valor_preco_base,
          fullPriceYear: plano.valor_preco_base,
          price: splitPriceMonth[0],
          decimalPrice: splitPriceMonth[1],
          iof: plano.aliquota_iof,
        };
      });

      setProducts(newProducts);
    }
  };

  const handleNavigate = (id: number) => {
    const { ev, ea, aa, np, ep } = correctorAndProducerStore.correctorData;

    productStore.setId(id);

    if (ev || ea || aa || np || ep) {
      navigate(
        setQueryParams(`/produto/empresa`, {
          ev,
          ea,
          aa,
          np,
          ep,
        })
      );
    } else {
      navigate("/produto");
    }
  };

  const navigateOut = () => {
    const ev = getQueryUrlParam("ev");
    const aa = getQueryUrlParam("aa");
    const ea = getQueryUrlParam("ea");
    const np = getQueryUrlParam("np");
    const ep = getQueryUrlParam("ep");

    if (ev || ea || aa || np || ep) {
      window.open(
        setQueryParams(`${process.env.REACT_APP_ODONTO_SITE}`, {
          ev,
          ea,
          aa,
          np,
          ep,
        })
      );
    } else {
      window.open(process.env.REACT_APP_ODONTO_SITE);
    }
  };

  useEffect(() => {
    defineProducts();
  }, []);

  return (
    <Fragment>
      {width > 1400 && (
        <ProductsDesktop
          products={products}
          navigate={handleNavigate}
          navigateOut={navigateOut}
        />
      )}
      {width > 475 && width <= 1400 && (
        <ProductsTablet
          products={products}
          navigate={handleNavigate}
          navigateOut={navigateOut}
        />
      )}
      {width <= 475 && (
        <ProductsMobile
          products={products}
          navigate={handleNavigate}
          navigateOut={navigateOut}
        />
      )}
    </Fragment>
  );
};

export default Products;
