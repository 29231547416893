import {
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  Grid,
  Divider,
  Card as CardMUI,
} from "@mui/material";
import { FlexGrid } from "../../common/grid/components/FlexGrid";
import styled from "styled-components";

type ItemProp = {
  $last?: boolean;
};

type CardHeaderProp = {
  color?: string;
};

export const StyledBox = styled(Box)`
  padding: 60px 120px;
  max-width: 1600px;
  margin: auto;

  && {
    @media (max-width: 1200px) {
      padding: 30px 60px;
    }

    @media (max-width: 475px) {
      padding: 40px 20px;
    }
  }
`;

export const ProductGrid = styled(FlexGrid)`
  justify-content: left;
  direction: row;
`;

export const Title = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 68px;

    color: #0b234c;

    @media (max-width: 475px) {
      font-size: 30px;
    }
  }
`;

export const Subtitle = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;

    color: #146cfa;
    margin-bottom: 10px;

    @media (max-width: 475px) {
      font-size: 16px;
      line-height: 21px;
    }
  }
`;

export const Text = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;

    color: #979797;
  }
`;

export const ImageText = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;

    color: #146cfa;
  }
`;

export const IconsImage = styled.img`
  height: 60px;
  width: 60px;
`;

export const IconsGrid = styled(Grid)`
  text-align: center;

  && {
    padding: 5px;

    & > p {
      margin: auto;
    }

    & > img {
      margin: auto;
      margin-bottom: 10px;
    }
  }
`;

export const BottomText = styled(Typography)`
  && {
    font-family: "Aestetico";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    top: -20px;
    color: "";
  }
`;

export const List = styled(Box)`
  display: flex;

  margin-bottom: 15px;

  align-items: baseline;
`;

export const PlanCard = styled(CardMUI)`
  && {
    background: #ffffff;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    margin-bottom: 15px;
  }
`;

export const CardHeader = styled(Box)<CardHeaderProp>`
  height: 40px;
  padding: 10px 20px;

  ${(props) => `background-color: ${props.color ? props.color : "#146cfa"};`}

  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;
  }
`;

export const BenefitBox = styled(Box)`
  padding: 20px 10px;
`;

export const BenefitDivider = styled(Divider)`
  && {
    margin: 0 20px;
    height: 1px;
    background-color: #d2d2d2;
  }
`;

export const BenefitImage = styled.img`
  height: 16px;
  width: 16px;
`;

export const BenefitTitle = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;

    margin-bottom: 10px;

    color: #0b234c;
  }
`;

export const BenefitText = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    white-space: pre-wrap;

    color: #5d5958;
  }
`;

export const BenefitObs = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    padding-bottom: 40px;
    color: #000000;
  }
`;

export const MenuButton = styled(Button)`
  && {
    display: flex;

    height: 30px;
    margin-left: 10px;

    font-family: Aestetico;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;

    text-transform: none;
    text-decoration-line: underline;

    color: #ff5000;

    @media (max-width: 475px) {
      font-size: 12px;
    }
  }
`;

export const StyledMenu = styled(Menu)`
  && > .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    padding: 15px 0;
  }
`;

export const StyledMenuItem = styled(MenuItem)<ItemProp>`
  && {
    justify-content: center;
    padding: 0 60px;
  }
`;

export const Item = styled(Button)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;

    text-align: center;
    text-transform: none;

    color: #ff5000;

    &:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }
`;

export const MenuDivider = styled(Divider)`
  && {
    height: 1px;
    width: 85%;
    margin: 18px auto;

    background-color: #dadada;
  }
`;

export const RegistryBox = styled(Box)`
  margin: 40px 0;
`;

export const RegistryText = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;

    color: #5a5a5a;
  }
`;
