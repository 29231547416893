/* eslint-disable no-useless-escape */
import qs from "qs";

export const loadLocalImage = (image: string, folder: string = "") => {
  return folder === ""
    ? require(`../assets/images/${image}`).default
    : require(`../assets/images/${folder}/${image}`).default;
};

export const getQueryUrlParam = (param: string) => {
  const { search } = window.location;
  const queryParams = new URLSearchParams(search);
  return queryParams.get(param) || null;
};

export const setQueryParams = (
  url: string,
  params: Record<string, any>
): string => {
  const queryParams = qs.stringify(
    Object.entries(params)
      .filter(([key, value]) => value !== undefined && value !== null)
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
  );

  if (queryParams) {
    const separator = url.includes("?") ? "&" : "?";
    url += separator + queryParams;
  }

  return url;
};
