import { Box, Grid, Typography } from "@mui/material";
import styled from "styled-components";

import { FlexGrid } from "../../common/grid/components/FlexGrid";

export const StyledBox = styled(Box)`
  background-color: #ffffff;
  padding: 0 100px;

  @media (max-width: 1024px) {
    padding: 0 50px;
  }
  
  @media (max-width: 900px) {
    padding: 0;
  }
`;

export const Title = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 3.25rem;

    padding-top: 75px;

    color: #146CFA;

    @media (max-width: 900px) {
      width: 600px;
      text-align: center;
    }

    @media (max-width: 475px) {
      font-size: 30px;
      line-height: 40px;

      padding-top: 0;
    }
  }
`;

export const Text = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 700;
    font-size: 1.57rem;
    line-height: 2.31rem;

    padding: 40px 0;
    width: 500px;

    color: #146CFA;
    @media (max-width: 900px) {
      text-align: center;
    }

    @media (max-width: 475px) {
      font-size: 18px;
      line-height: 24px;

      padding-right: 15px;
    }
  }
`;

export const Image = styled.img`
  height: 527px;
  width: 366px;

  && {
    @media (max-width: 1024px) {
      height: 427px;
      width: 297px;
    }

    @media (max-width: 900px) {
      height: 497px;
      width: 345px;
      margin-bottom: 23px;
    }

    @media (max-width: 475px) {
      height: 327px;
      width: 227px;
    }
  }
`;

export const TextGrid = styled(Grid)`
  justify-content: center;
`;

export const ImageGrid = styled(Grid)`
  && {
    display: flex;

    justify-content: right;

    @media (max-width: 900px) {
      justify-content: center;
      flex-wrap: wrap;
      align-content: center;
    }
  }
`;

export const ButtonGroup = styled.div`
  && {
    width: 345px;
    position: relative;
    z-index: 2;
    cursor: pointer;

    @media (max-width: 475px) {
      width: 317px;
    }
  }
`;

export const DesktopVersion = styled(FlexGrid)`
  && {
    display: flex;
    max-width: 1400px;

    margin: 0 auto;

    padding-top: 150px;
    padding-bottom: 30px;

    @media (max-width: 1280px) {
      padding: 30px;
    }

    @media (max-width: 900px) {
      display: none;
    }
  }
`;

export const MobileVersion = styled(FlexGrid)`
  && {
    display: none;

    padding: 30px;

    @media (max-width: 900px) {
      display: flex;
    }
  }
`;
