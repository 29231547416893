import { Fragment, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import Products from "./Products/components/Products";
import Network from "./Network";
import Banner from "./Banner";
import { Advantages } from "./Advantages/Advantages";
import { CommonQuestions } from "./CommonQuestions/components/CommonQuestions";
import useGoogleTagManager from "../../../hooks/useGTM";
import { observer } from "mobx-react-lite";

const Home = observer(() => {
  const { pathname, hash, key } = useLocation();
  const { pageInfoEvent } = useGoogleTagManager();

  useMemo(() => {
    pageInfoEvent({ page_location: "/home", page_path: pathname });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);

        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <Fragment>
      <Banner />
      <Products />
      <Advantages />
      <Network />
      <CommonQuestions />
    </Fragment>
  );
});

export default Home;
