import { Title } from "../styles/Copy";
import { Grid } from "@mui/material";

export const Copy = () => (
  <Grid container>
    <Grid item xs={12} marginBottom={2}>
      <Title>
        @{new Date().getFullYear()} Sul América Companhia de Seguro Saúde - Todos os direitos
        reservados CNPJ: 01.685.053/0001-56 - Registro ANS: 006246 - Rua
        Beatriz Larragoiti Lucas, 121 - Cidade Nova - Rio de Janeiro/RJ - CEP:
        20211-903
      </Title>
    </Grid>
  </Grid>
);
