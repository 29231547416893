import { Grid } from "@mui/material";
import { loadLocalImage } from "../../../../utils";
import {
  SocialMediaContainer,
  SocialMediaLink,
  SocialMediaItem,
  SocialMediaText,
} from "../styles/SocialMediaBoxStyles";

export const SocialMediaBox = () => {
  const socialMediaData = [
    {
      link: "https://www.facebook.com/SulAmerica",
      icon: "facebook.png",
      title: "Facebook",
    },
    {
      link: "https://www.instagram.com/sulamerica/?hl=pt-br",
      icon: "instagram.png",
      title: "Instagram",
    },

    {
      link: "https://twitter.com/SulAmerica",
      icon: "twitter.png",
      title: "Twitter",
    },
    {
      link: "https://www.youtube.com/SulAmerica",
      icon: "youtube.png",
      title: "Youtube",
    },
    {
      link: "https://www.linkedin.com/company/sulamerica?originalSubdomain=br",
      icon: "likedin.png",
      title: "Likedin",
    },
  ];

  return (
    <SocialMediaContainer>
      <Grid container marginBottom={1}>
        <SocialMediaText>Siga-nos</SocialMediaText>
      </Grid>
      <Grid container>
        {socialMediaData.map(({ link, icon, title }) => (
          <SocialMediaItem item xs={2.4} md={2} key={`social${title}`}>
            <SocialMediaLink
              target="_blank"
              title={title}
              href={link}
              src={loadLocalImage(icon, "footer")}
            />
          </SocialMediaItem>
        ))}
      </Grid>
      <Grid container marginTop={1}>
        <SocialMediaText>
          <b style={{ marginRight: 10 }}>Dúvidas? </b>
        </SocialMediaText>
        <SocialMediaText>3003-1159</SocialMediaText>
      </Grid>
    </SocialMediaContainer>
  );
};
