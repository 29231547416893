import { Container, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import TopArrow from "../../../../assets/images/footer/backArrow.png";
export const StyledBox = styled.footer`
  && {
    background-color: #001e64;
    position: relative;
  }
`;

export const ContainerGrid = styled(Container)`
  && {
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1280px) {
      padding: 40px 25px;
    }
  }
`;

export const Content = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media screen and (max-width: 960px) {
    flex-direction: column !important;
    justify-content: center;
  }
`;

export const Title = styled(Typography)`
  && {
    color: #8a8a8a;
    font-size: 1rem;
    line-height: 26.5px;
    font-weight: 700;
    margin-bottom: 10px;
  }
`;

export const SubTitle = styled(Typography)`
  && {
    color: #8a8a8a;
    font-size: 1rem;
    line-height: 26.5px;
  }
`;
export const BackToTopArrow = styled.a`
  && {
    background: url(${TopArrow}) no-repeat;
    width: 80px;
    height: 81px;
    background-size: 100%;
    position: absolute;
    top: -50px;
    right: 30px;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      top: -25px;
      right: 10px;
      width: 55px;
      height: 56px;
    }
  }
`;
export const SulaLogoImage = styled.img`
  && {
    width: 90%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const AnsImage = styled.img`
  && {
  }
`;

export const AnsImageLink = styled.a`
  && {
    cursor: pointer;
  }
`;
