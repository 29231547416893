import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./bundles/home/components/Home";
import Product from "./bundles/product/components/Product";
import { observer } from "mobx-react-lite";

const Router = observer(() => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/empresa" element={<Home />} />
      <Route path="/produto" element={<Product />} />
      <Route path="/produto/empresa" element={<Product />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
});

export default Router;
