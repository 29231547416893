import Grid from "@mui/material/Grid";
import styled from "styled-components";

type JustifyContent = "left" | "right" | "center";

export const FlexGrid = styled(Grid)<{ justifyContent?: JustifyContent }>`
  && {
    display: flex;
    justify-content: ${(props) =>
      props.justifyContent ? props.justifyContent : "center"};
  }
`;
