import {
  ButtonGroup,
  DesktopVersion,
  Image,
  ImageGrid,
  MobileVersion,
  StyledBox,
  Text,
  TextGrid,
  Title,
} from "../styles/NetworkStyles";

import BrazilPin from "../../../assets/images/brazil-pin.png";
import Button from "../../common/button/components/Button";
import useGoogleTagManager from "../../../hooks/useGTM";

const Network = () => {
  const { interactionEvent } = useGoogleTagManager();

  const NetSite = () => {
    interactionEvent({
      event_action: "clicou",
      event_category: "rede_credenciada",
      event_label: "conheca_a_rede_credenciada",
      event_name: `conheca_a_rede_credenciada`,
    });
    window.open(process.env.REACT_APP_NETWORK);
  };

  return (
    <StyledBox id="rede">
      <DesktopVersion container>
        <TextGrid item sm={12} md={7} lg={7}>
          <Title>
            O benefício mais desejado pelo seu colaborador em todo Brasil
          </Title>
          <Text>
            Ofereça a proteção SulAmérica com mais de 34 mil opções de
            atendimento e profissionais especializados.
          </Text>
          <ButtonGroup>
            <Button
              type="button"
              variant="contained"
              background="fill"
              onClick={NetSite}
            >
              Confira a rede Credenciada
            </Button>
          </ButtonGroup>
        </TextGrid>
        <ImageGrid item sm={12} md={5} lg={5}>
          <Image src={BrazilPin} alt="brasil" />
        </ImageGrid>
      </DesktopVersion>
      <MobileVersion container>
        <Title>
          O benefício mais desejado pelo seu colaborador em todo Brasil
        </Title>
        <Text>
          Ofereça a proteção SulAmérica com mais de 34 mil opções de atendimento
          e profissionais especializados.
        </Text>
        <ImageGrid item xs={12} justifyContent="center">
          <Image src={BrazilPin} alt="brasil" />
        </ImageGrid>
        <ButtonGroup>
          <Button
            type="button"
            variant="contained"
            background="fill"
            onClick={NetSite}
          >
            Confira a rede Credenciada
          </Button>
        </ButtonGroup>
      </MobileVersion>
    </StyledBox>
  );
};

export default Network;
