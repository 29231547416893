interface PageInfoEvent {
  page_path: string;
  page_location: string;
}

interface EcommerceInfo {
  items: EcommerceItem[];
  value?: number;
  valor_pago?: number;
  coupon?: number;
  transaction_id?: number;
  parcelamento?: number;
  meio_pagamento?: number;
  currency: string;
  tipo_contratacao: string;
}

interface EcommerceItem {
  item_name: string;
  quantity: number;
  price: number;
}

interface UserDataEvent {
  user_id: string;
  scode: string;
  corretor: string;
}

interface InteractionEvent {
  event_name: string;
  event_category?: string;
  event_action?: string;
  event_label?: string;
  select_item?: string;
  tipo_plano?: string;
  meio_pagamento?: string;
  parcelamento?: string;
}
interface UserEvent {
  user: UserDataEvent;
}
interface EcommerceEvent {
  eventName: string;
  ecommerce: EcommerceInfo;
}

const useGoogleTagManager = () => {
  const sendEvent = (eventName: string, eventData: object) => {
    // Verifica se o dataLayer padrão do GTM está definido
    if (typeof window !== "undefined" && !window.dataLayer) {
      window.dataLayer = [];
    }

    // Função para enviar um evento para o dataLayer padrão do GTM
    window.dataLayer.push({
      event: eventName,
      ...eventData,
    });
  };

  const pageInfoEvent = ({ page_path, page_location }: PageInfoEvent) => {
    if (window.dataLayer) {
      const eventExists = window.dataLayer.find(
        (item) =>
          item.event === "page_view" && item.page.page_location == page_location
      );

      if (!eventExists) {
        sendEvent("page_view", {
          page: {
            page_path,
            page_location,
          },
        });
      }
    }
  };

  const interactionEvent = (eventInfo: InteractionEvent) => {
    sendEvent("interaction", {
      event_info: {
        ...eventInfo,
      },
    });
  };

  const userPropreties = ({ user }: UserEvent) => {
    if (window.dataLayer) {
      const eventExists = window.dataLayer.find(
        (item) => item.event === "UserInfo"
      );

      if (!eventExists) {
        sendEvent("UserInfo", {
          user: {
            ...user,
          },
        });
      }
    }
  };

  const ecommerceEvent = ({ eventName, ecommerce }: EcommerceEvent) => {
    sendEvent(eventName, {
      ecommerce: {
        ...ecommerce,
      },
    });
  };

  return {
    sendEvent,
    pageInfoEvent,
    interactionEvent,
    ecommerceEvent,
    userPropreties,
  };
};

export default useGoogleTagManager;
