import styled from "styled-components";
import { Box, Link, Typography } from "@mui/material";
import { FlexGrid } from "../../../../common/grid/components/FlexGrid";

type CheckProps = {
  color: "white" | "orange";
};

export const Card = styled(Box)`
  width: 290px;

  padding: 40px 0 15px 0;
  margin: 10px;

  background: #ffffff;
  mix-blend-mode: normal;

  box-shadow: 0px -1px 18px 4px rgba(0, 0, 0, 0.21);
  border-radius: 4px;

  @media (max-width: 475px) {
    padding: 27px 0;
  }
`;

export const BoxTitle = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 33px;

    text-align: center;

    color: #0b234c;
  }
`;

export const BoxSubtitle = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;

    text-align: center;
    margin: 15px 0 10px 0;
    height: 30px;

    white-space: pre-wrap;

    color: #828282;
  }
`;

export const BoxText = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;

    text-align: center;

    color: #0b234c;

    & > span {
      color: #146efa;
      font-weight: 700;
    }
  }
`;

export const BoxPrice = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;

    text-align: center;
    color: #fd6520;

    & > span {
      font-size: 50px;
      line-height: 34px;
    }
  }
`;

export const MiniBoxPrice = styled(BoxPrice)`
  && {
    text-align: center;
  }
`;

export const ValueSave = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    margin: auto;
    text-align: center;

    color: #ffffff;

    background-color: #146efa;
  }
`;

export const HighlightTitle = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;

    text-align: center;
    margin-top: 15px;

    color: #146efa;
  }
`;

export const HighlightBlock = styled(Box)`
  display: flex;
  padding: 0 28px;
  margin: 15px 0;
  background-color: #146cfa;
  min-height: 80px;
`;

export const HighlightText = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    color: #ffffff;
  }
`;

export const Check = styled.img<CheckProps>`
  height: 14px;

  margin-right: 10px;
  padding-top: 5px;

  ${(props) => `
    filter: ${
      props.color === "white"
        ? "none"
        : "invert(55%) sepia(93%) saturate(1901%) hue-rotate(0deg) brightness(102%) contrast(101%)"
    }
  `}
`;

export const TeethIcon = styled.img`
  margin-right: 10px;
`;

export const CheckTitle = styled(Typography)`
  && {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;

    padding: 10px 0;

    color: #323232;
  }
`;

export const Block = styled(Box)`
  display: flex;
  align-items: center;
  padding: 5px 28px;
  min-height: 75px;
`;

export const BoxButton = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 15px;
`;

export const BoxValue = styled(Box)`
  margin-top: 10px;

  @media (min-width: 476px) {
    padding: 0 15px;
  }

  && {
    @media (max-width: 475px) {
      height: 100px;
    }
  }
`;

export const DesktopVersion = styled(FlexGrid)`
  && {
    display: flex;
    max-width: 1920px;
    margin: -100px auto 30px auto;

    @media (max-width: 1400px) {
      margin: -80px auto 30px auto;
    }

    @media (max-width: 900px) {
      margin-top: -60px;
    }

    @media (max-width: 475px) {
      display: none;
    }
  }
`;

export const MobileVersion = styled(FlexGrid)`
  && {
    display: none;
    margin-top: -360px;
    margin-bottom: 10px;
    height: 400px;

    @media (max-width: 475px) {
      display: flex;
      height: 750px;
      margin-top: -500px;
    }
    @media (max-width: 340px) {
      display: flex;
      margin-top: -390px;
    }
  }
`;

export const TabletVersion = styled(FlexGrid)`
  && {
    display: flex;

    height: 760px;

    margin: -80px auto 0 auto;
  }
`;

export const Observation = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.03em;

    color: #444142;
  }
`;

export const BlueText = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 16px;

    white-space: pre-wrap;
    color: #146efa;
  }
`;

export const Text = styled(Typography)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin: 15px 0;
    color: #828282;
  }
`;

export const PriceText = styled(Typography)`
  && {
    font-family: "Aestetico";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    white-space: pre-wrap;
    color: #ff5000;
  }
`;

export const PriceBoxText = styled(BoxText)`
  && {
    font-family: "Aestetico";
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 18px;

    white-space: pre-wrap;
    color: #ff5000;
  }
`;

export const ProductLink = styled(Link)`
  && {
    font-family: Aestetico;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;

    text-align: center;
    text-decoration-line: underline;
    cursor: pointer;

    text-decoration-color: #ff5000;
    color: #ff5000;
  }
`;
