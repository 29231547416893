/* eslint-disable react-hooks/exhaustive-deps */
import { useState, Fragment, useEffect } from "react";
import Logo from "../../../../assets/images/logo.png";
import MenuImage from "../../../../assets/images/menu-icon.svg";
import { FlexGrid } from "../../grid/components/FlexGrid";
import {
  LogoBox,
  LogoImage,
  MenuButton,
  MenuIcon,
  MenuLink,
  Navbar,
  NavDivider,
  Navlink,
  StyledBox,
  StyledMenu,
  StyledMenuItem,
} from "../styles/HeaderStyles";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import useStores from "../../../../hooks/useStores";
import { getQueryUrlParam, setQueryParams } from "../../../../utils";
import useGoogleTagManager from "../../../../hooks/useGTM";

const Header = observer(() => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { correctorAndProducerStore } = useStores();
  const { correctorData, setCorrector } = correctorAndProducerStore;

  const { interactionEvent } = useGoogleTagManager();

  const gtmHandleClick = (linkName: string) => {
    interactionEvent({
      event_action: "clicou",
      event_category: "header",
      event_label: `${linkName}`,
      event_name: `clicou_header_${linkName}`,
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = () => {
    const { ev, ea, aa, np, ep } = correctorAndProducerStore.correctorData;

    if (ev || ea || aa || np || ep) {
      navigate(
        setQueryParams(`/empresa`, {
          ev,
          ea,
          aa,
          np,
          ep,
        })
      );
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    setCorrector({
      ...correctorData,
      ev: getQueryUrlParam("ev"),
      aa: getQueryUrlParam("aa"),
      ea: getQueryUrlParam("ea"),
      np: getQueryUrlParam("np"),
      ep: getQueryUrlParam("ep"),
    });
  }, []);

  return (
    <Fragment>
      <StyledBox id="inicio">
        <FlexGrid container>
          <FlexGrid item xs={3} sm={4} lg={6} justifyContent="left">
            <LogoBox onClick={handleNavigate}>
              <LogoImage src={Logo} />
            </LogoBox>
          </FlexGrid>
          <Navbar item justifyContent="right">
            <Navlink
              smooth
              to="/#inicio"
              onClick={() => gtmHandleClick("pagina_inicial")}
            >
              Página Inicial
            </Navlink>
            <NavDivider orientation="vertical" />
            <Navlink
              smooth
              to="/#rede"
              onClick={() => gtmHandleClick("rede_credenciada")}
            >
              Rede Credenciada
            </Navlink>
            <NavDivider orientation="vertical" />
            <Navlink
              smooth
              to="/#vantagens"
              onClick={() => gtmHandleClick("vantagens")}
            >
              Vantagens
            </Navlink>
            <NavDivider orientation="vertical" />
            <Navlink
              smooth
              to="/#duvidas"
              onClick={() => gtmHandleClick("duvidas")}
            >
              Dúvidas?
            </Navlink>
          </Navbar>
          <FlexGrid
            item
            xs={9}
            sm={2}
            justifyContent="right"
            alignItems="center"
          >
            <MenuButton
              id="menu-button"
              aria-controls={open ? "menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MenuIcon src={MenuImage} />
            </MenuButton>
            <StyledMenu
              id="menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "menu-button",
              }}
            >
              <StyledMenuItem onClick={handleClose}>
                <MenuLink smooth to="/#inicio">
                  Página Inicial
                </MenuLink>
              </StyledMenuItem>
              <StyledMenuItem onClick={handleClose}>
                <MenuLink smooth to="/#rede">
                  Rede Credenciada
                </MenuLink>
              </StyledMenuItem>
              <StyledMenuItem onClick={handleClose}>
                <MenuLink smooth to="/#vantagens">
                  Vantagens
                </MenuLink>
              </StyledMenuItem>
              <StyledMenuItem onClick={handleClose} $last={true}>
                <MenuLink smooth to="/#duvidas">
                  Dúvidas?
                </MenuLink>
              </StyledMenuItem>
            </StyledMenu>
          </FlexGrid>
        </FlexGrid>
      </StyledBox>
    </Fragment>
  );
});

export default Header;
