import { Box, Button, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import PhoneIcon from "../../../../assets/images/footer/phone.png";
/// SCC DATA
export const SCCContainer = styled(Box)``;

export const SCCContent = styled(Box)``;

export const Title = styled(Typography)`
  && {
    color: #8a8a8a;
    font-size: 1rem;
    line-height: 26.5px;
    font-weight: 700;
    margin-bottom: 10px;
    position: relative;
    top: 5px;
    left: 5px;
  }
`;

export const SubTitle = styled(Typography)`
  && {
    color: #8a8a8a;
    font-size: 1rem;
    line-height: 26.5px;
    margin-bottom: 10px;
  }
`;

export const SCCIcon = styled.div`
  && {
    background: url(${PhoneIcon}) no-repeat;
    background-position: center;
    background-size: 55%;
    width: 42px;
    height: 42px;
  }
`;

export const SCCItem = styled(Grid)`
  && {
    max-width: 100%;
    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (max-width: 960px) {
      text-align: center;
      padding: 0 10px;
    }
  }
`;

export const SCCButton = styled(Button)`
  && {
    background-color: transparent;
    color: #ffffff;
    border-radius: 27px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 15px;
    border: 1px solid #ffffff;

    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }
`;

/// SCC DATA
