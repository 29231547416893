import styled from "styled-components";
import { Accordion, Container, TextField, Typography } from "@mui/material";
import SearchIcon from "../../../../../assets/images/search-icon.svg";

interface TitleProps {
  $isexpanded: boolean;
}

export const Wrapper = styled.section`
  background: #f7f7f5;
  padding-top: 2rem;
`;

export const CustomContainer = styled(Container)`
  && {
    padding: 20px 20px 200px 20px;
    display: flex;
    flex-direction: column;
  }
`;

export const MainTitle = styled(Typography)`
  && {
    color: #0b234c;
    font-weight: 700;
    font-size: 58px;
    width: 100%;
    margin-bottom: 2rem;
  }
`;

export const CustomAccordion = styled(Accordion)`
  && {
    margin-bottom: 10px;
    background: #ffffff;
    box-shadow: none;
    border: 0;
    position: inherit;
    padding: 10px;

    @media screen and (max-width: 900px) {
      background: transparent;
      border-bottom: 1px solid #d2d2d2;
    }
  }
`;

export const Title = styled(Typography)<TitleProps>`
  && {
    color: ${({ $isexpanded }) => ($isexpanded ? "#001E64" : "#0B234C")};
    font-weight: ${({ $isexpanded }) => ($isexpanded ? "700" : "400")};
  }
`;
export const SubTitle = styled(Typography)`
  && {
    color: rgba(60, 60, 67, 0.85);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
`;

export const SearchInput = styled(TextField)`
  && {
    background-color: #ffffff;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 25px;

    .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    .MuiInputLabel-formControl {
      font-size: 20px;
    }

    .MuiInputBase-formControl {
      font-size: 20px;
      &::placeholder {
        color: #979797;
      }
    }
  }
`;

export const SearchInputIcon = styled.span`
  background: url(${SearchIcon});
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
`;

export const ActionButton = styled.a`
  justify-content: center;
  background: #ff5000;
  color: #fff;
  font-weight: 600;
  padding: 15px 30px;
  cursor: pointer;
  display: none;
  margin-top: 20px;
  @media screen and (max-width: 900px) {
    display: flex;

    width: 100%;
  }
`;
