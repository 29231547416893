import { Grid } from "@mui/material";
import { AdvantageCards } from "./components/AdvantageCards";
import {
  CustomContainer,
  Observation,
  StyledBox,
  SubTitle,
  Title,
  TitleContainer,
} from "./styles/AdvantagesStyles";

export const Advantages = () => {
  return (
    <StyledBox>
      <CustomContainer>
        <Grid container spacing={{ xs: 16, sm: 10, md: 12 }}>
          <TitleContainer item xs={12}>
            <Title id="vantagens" variant="h1">
              O plano ideal para a sua empresa com a qualidade SulAmérica que
              você já conhece.
            </Title>
            <SubTitle variant="h2">
              São muitos benefícios para garantir o bem-estar dos seus
              colaboradores e o sucesso do seu negócio.
            </SubTitle>
          </TitleContainer>
          <Grid item xs={12}>
            <AdvantageCards />
          </Grid>
        </Grid>
      </CustomContainer>
      <Observation>*Respeitando as regras de inclusão da apólice</Observation>
    </StyledBox>
  );
};
