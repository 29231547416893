import { Box, Grid } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "../../../../common/button/components/Button";
import { FlexGrid } from "../../../../common/grid/components/FlexGrid";

import Skeleton from "@mui/material/Skeleton";

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { Pagination, Navigation } from "swiper";

import {
  Block,
  BoxButton,
  BoxPrice,
  BoxSubtitle,
  BoxTitle,
  BoxValue,
  Card,
  CheckTitle,
  HighlightBlock,
  HighlightText,
  MobileVersion,
  Observation,
  PriceText,
  ProductLink,
  TeethIcon,
} from "../styles/ProductsStyles";
import { ProductProps } from "./Products";
import { loadLocalImage } from "../../../../../utils";
import useGoogleTagManager from "../../../../../hooks/useGTM";

const ProductsMobile = (props: ProductProps) => {
  const { products, navigateOut } = props;
  const { interactionEvent } = useGoogleTagManager();

  const pagination = {
    clickable: true,
    renderBullet: (index: number, className: string) =>
      `<span class="${className}"></span>`,
  };

  const gtmHandleClick = (planName: string, id: number) => {
    interactionEvent({
      event_action: "clicou",
      event_category: "catalogo_planos_home",
      event_label: "conheca_ja",
      event_name: `ver_detalhes_do_produto`,
      select_item: `${planName}`,
    });
    props.navigate(id);
  };

  return (
    <MobileVersion container>
      <Swiper
        keyboard={true}
        navigation={true}
        grabCursor={true}
        pagination={pagination}
        modules={[Pagination, Navigation]}
      >
        {products?.map((data: any) => (
          <SwiperSlide key={data.title}>
            <FlexGrid item sm={6} md={6} lg={3} sx={{ minWidth: "300px" }}>
              <Card>
                <Grid padding={1}>
                  <BoxTitle>{data.title}</BoxTitle>
                  <BoxValue>
                    <BoxSubtitle>{data.subtitle}</BoxSubtitle>
                    {data.price && (
                      <Grid
                        container
                        flexDirection={"column"}
                        justifyContent="flex-start"
                        sx={{ marginTop: "30px" }}
                      >
                        <Grid container alignItems={"flex-end"}>
                          <FlexGrid item xs={8.5}>
                            <BoxPrice>
                              R$ <span>{data.price}</span>,{data.decimalPrice}*
                            </BoxPrice>
                          </FlexGrid>
                          <Grid item xs={3.5} marginBottom={0.5}>
                            <PriceText>mensais{"\n"}por pessoa</PriceText>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {!data.price && <Skeleton width="100%" />}
                  </BoxValue>
                </Grid>
                {data.checkItems.map((item: any, index: number) => (
                  <Grid key={index}>
                    {index === 0 ? (
                      <HighlightBlock>
                        <TeethIcon
                          src={loadLocalImage(item.icon, "")}
                          alt="teeth icon"
                        />
                        <HighlightText>{item.text}</HighlightText>
                      </HighlightBlock>
                    ) : (
                      <Block>
                        <TeethIcon
                          src={loadLocalImage(item.icon, "")}
                          alt="teeth icon"
                        />
                        <Box>
                          <CheckTitle>{item.text}</CheckTitle>
                        </Box>
                      </Block>
                    )}
                  </Grid>
                ))}
                <BoxButton>
                  <Button
                    type="button"
                    variant="contained"
                    background="fill"
                    width="232px"
                    bgcolor="#FF5000"
                    onClick={navigateOut}
                  >
                    Contrate Agora
                  </Button>
                </BoxButton>
                <FlexGrid container sx={{ marginTop: "15px" }}>
                  <ProductLink
                    onClick={() => gtmHandleClick(data.title, data.id)}
                  >
                    Clique aqui e saiba mais
                  </ProductLink>
                </FlexGrid>
                <FlexGrid container>
                  <Observation>
                    *Acréscimo de IOF (
                    {data.iof !== null ? (
                      data.iof.toFixed(2).replace(".", ",")
                    ) : (
                      <Skeleton
                        sx={{ display: "inline-flex", width: "10px" }}
                      />
                    )}
                    %)
                  </Observation>
                </FlexGrid>
              </Card>
            </FlexGrid>
          </SwiperSlide>
        ))}
      </Swiper>
    </MobileVersion>
  );
};

export default ProductsMobile;
