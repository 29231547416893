import styled from "styled-components";
import { Box, Card } from "@mui/material";
import Typography from "@mui/material/Typography";

const mainColor = "#146cfa";

export const CustomCard = styled(Card)`
  && {
    height: 243px;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    position: relative;
    &:hover {
      .hoverText {
        display: flex !important;
        transition: 3s;
      }
    }
  }
`;

export const CustomCardHeader = styled(Box)`
  background-color: ${mainColor};
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CustomIcon = styled.img``;

export const CustomCardTextContainer = styled(Box)`
  && {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
`;

export const CustomCardText = styled(Typography)`
  && {
    color: ${mainColor};
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    max-width: 140px;
  }
`;
