import { Grid } from "@mui/material";
import { loadLocalImage } from "../../../../utils";
import {
  AnsImage,
  AnsImageLink,
  BackToTopArrow,
  ContainerGrid,
  StyledBox,
  SulaLogoImage,
} from "../styles/FooterStyles";
import { Copy } from "./Copy";
import { ServiceChannelsBox } from "./ServiceChannelsBox";
import { SocialMediaBox } from "./SocialMediaBox";

export const Footer = () => {
  return (
    <StyledBox>
      <BackToTopArrow href="#inicio" />
      <ContainerGrid>
        <Grid
          container
          marginBottom={4}
          alignItems="center"
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Grid item xs={12} sm={3} marginBottom={{ xs: 3 }}>
            <SulaLogoImage
              alt="SulAmérica"
              src={loadLocalImage("sulamerica_logo_white.svg", "footer")}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <AnsImageLink href="https://www.gov.br/ans/pt-br" target="_blank">
              <Grid
                container
                alignItems="center"
                flexDirection="column"
                justifyContent={{ sm: "flex-start" }}
              >
                <Grid item xs={12}>
                  <AnsImage src={loadLocalImage("ansfull.svg", "footer")} />
                </Grid>
                <Grid item xs={12}>
                  <AnsImage src={loadLocalImage("ans1.png", "footer")} />
                </Grid>
              </Grid>
            </AnsImageLink>
          </Grid>

          <Grid item xs={12} sm={3.5} marginTop={{ xs: 2 }}>
            <ServiceChannelsBox />
          </Grid>
          <Grid item xs={12} sm={2.5}>
            <SocialMediaBox />
          </Grid>
        </Grid>
        <Grid container>
          <Copy />
        </Grid>
      </ContainerGrid>
    </StyledBox>
  );
};
